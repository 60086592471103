<template>
    <CommonModalAlert
        :show="showCancelPromoModal"
        :class-custom="''"
        sticky
        @close="closeSelf"
    >
        <template #header> </template>
        <template #default>
            <div
                class="flex min-h-[340px] flex-col items-center justify-center rounded-[8px] bg-white p-[12px] text-sm font-medium"
            >
                <div class="">
                    <div class="flex w-full justify-end">
                        <img
                            class="cursor-pointer"
                            :src="`${staticUrl}/assets/img/modal-cancel-promotion/close.svg`"
                            alt="close"
                            @click="closeSelf"
                        />
                    </div>

                    <div class="flex min-h-[50px] justify-center">
                        <img
                            class="mb-[20px]"
                            :src="`${staticUrl}/assets/img/modal-cancel-promotion/alert.svg`"
                            alt="close"
                        />
                    </div>

                    <div class="w-full px-[12px]">
                        <div class="text-lg font-bold text-[#1A181B]">
                            {{ $t('cancel_promotion.change_promotion') }}
                        </div>

                        <div class="mt-[32px] text-[14px]">
                            {{ $t('cancel_promotion.are_you_sure') }}
                        </div>

                        <div class="mb-[32px] text-[14px]">
                            {{ $t('cancel_promotion.action') }}
                        </div>
                    </div>

                    <div class="mb-[32px] flex w-full gap-[16px]">
                        <button
                            type="button"
                            class="pointer-events-auto w-[50%] rounded bg-bigg-gray bg-gradient-to-tr px-5 py-2.5 text-center text-[16px] text-black hover:bg-grey-100"
                            @click="closeSelf"
                        >
                            {{ $t('common.cancel') }}
                        </button>

                        <button
                            type="button"
                            class="pointer-events-auto flex w-[50%] items-center justify-center rounded bg-[#F7D936] text-center text-[16px] text-black"
                            :class="{
                                '!pointer-events-none opacity-60 hover:bg-[#F7D936]':
                                    isLoading,
                            }"
                            @click="handleCancelPromo"
                        >
                            <span v-if="!isLoading">OK</span>
                            <img
                                v-if="isLoading"
                                :src="`${staticUrl}/assets/img/loader/spinner-white.svg`"
                                class="h-[30px] w-[30px]"
                                alt="spinner-loader"
                                srcset=""
                            />
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModalAlert>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore, usePromotionStore, useAlertStore } from '~/stores'
const staticUrl = useRuntimeConfig().public.staticUrl
const isLoading = ref(false)

const useModalStoreInstance = useModalStore()
const useAlertStoreInstance = useAlertStore()
const usePromotionStoreInstance = usePromotionStore()

const { showCancelPromoModal } = storeToRefs(useModalStoreInstance)
const { cancelPromotion } = usePromotionStoreInstance

const closeSelf = () => {
    showCancelPromoModal.value = false
}

const handleCancelPromo = async () => {
    try {
        isLoading.value = true
        await cancelPromotion()
        // reload to update package
        isLoading.value = false
        closeSelf()
        setTimeout(() => reloadNuxtApp())
    } catch (error) {
        useAlertStoreInstance.showMessageError(error)
    } finally {
        showCancelPromoModal.value = false
    }
}
</script>
