<template>
    <div
        class="relative flex w-full cursor-pointer items-center justify-start gap-2 rounded-md bg-bigg-yellow px-3 py-2 text-black"
        @click="loadLiveChat"
    >
        <div class="w-6">
            <img
                :src="`${
                    useRuntimeConfig().public.staticUrl
                }/assets/img/footer/live-chat.svg`"
                alt="live chat"
            />
        </div>
        <div class="absolute left-1/2 -translate-x-1/2 text-sm font-medium">
            {{ $t('contact.live_chat') }}
        </div>
    </div>
</template>
<script setup>
const { isMobile } = useDevice()
function loadLiveChat() {
    if (isMobile) {
        const openNewTab = window.open('about:blank', '_blank')
        if (openNewTab) {
            openNewTab.location.href = useRuntimeConfig().public.LIVE_CHAT_LINK
        }
    } else {
        if (typeof window !== 'undefined') {
            window.LiveChatWidget.call('maximize')
        }
    }
}
</script>
