<template>
    <div
        class="flex max-h-[42px] min-h-[42px] min-w-[140px] max-w-[140px] cursor-pointer items-center justify-center rounded-[10px] bg-[color:#F7D936] py-[11px] text-xs font-medium text-black"
        :class="classes"
        @click="handleClick"
    >
        <img
            class="mr-[14px] max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px]"
            :src="`${
                useRuntimeConfig().public.staticUrl
            }/assets/icons/button/${icon}`"
            alt="icon"
        />
        {{ content }}
    </div>
</template>
<script setup>
const { content, icon, classes } = defineProps({
    content: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
        default: '',
    },
    classes: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['onclick'])

const handleClick = () => {
    if (content === 'LIVE CHAT' && typeof window !== 'undefined') {
        if (typeof window !== 'undefined') {
            window.LiveChatWidget.call('maximize')
        }
    }
    emit('onclick')
}
</script>
