<template>
    <div
        class="hidden h-[48px] w-[100%] items-end justify-center gap-[40px] lg:flex"
    >
        <div
            class="step relative flex h-[36px] w-[225px] items-center justify-center text-[16px] text-[#555555]"
            :class="{
                'active-step': stepActive === INIT_STEP.PERSONAL_INFO,
                'finish-step': stepActive > INIT_STEP.PERSONAL_INFO,
            }"
        >
            1. {{ $t('kyc.personal_information') }}
            <svg
                v-if="stepActive > INIT_STEP.PERSONAL_INFO"
                class="ml-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.531 7.28055L9.53104 19.2806C9.46139 19.3503 9.37867 19.4056 9.28762 19.4433C9.19657 19.4811 9.09898 19.5005 9.00042 19.5005C8.90186 19.5005 8.80426 19.4811 8.71321 19.4433C8.62216 19.4056 8.53945 19.3503 8.46979 19.2806L3.21979 14.0306C3.07906 13.8898 3 13.699 3 13.4999C3 13.3009 3.07906 13.11 3.21979 12.9693C3.36052 12.8286 3.55139 12.7495 3.75042 12.7495C3.94944 12.7495 4.14031 12.8286 4.28104 12.9693L9.00042 17.6896L20.4698 6.2193C20.6105 6.07857 20.8014 5.99951 21.0004 5.99951C21.1994 5.99951 21.3903 6.07857 21.531 6.2193C21.6718 6.36003 21.7508 6.55091 21.7508 6.74993C21.7508 6.94895 21.6718 7.13982 21.531 7.28055Z"
                    fill="#008D0E"
                />
            </svg>
        </div>
        <div
            class="step relative flex h-[36px] w-[225px] items-center justify-center text-[16px] text-[#555555]"
            :class="{
                'active-step': stepActive === INIT_STEP.UPLOAD_DOCUMENT,
                'finish-step': stepActive > INIT_STEP.UPLOAD_DOCUMENT,
            }"
        >
            2. {{ $t('kyc.upload_document') }}
            <svg
                v-if="stepActive > INIT_STEP.UPLOAD_DOCUMENT"
                class="ml-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.531 7.28055L9.53104 19.2806C9.46139 19.3503 9.37867 19.4056 9.28762 19.4433C9.19657 19.4811 9.09898 19.5005 9.00042 19.5005C8.90186 19.5005 8.80426 19.4811 8.71321 19.4433C8.62216 19.4056 8.53945 19.3503 8.46979 19.2806L3.21979 14.0306C3.07906 13.8898 3 13.699 3 13.4999C3 13.3009 3.07906 13.11 3.21979 12.9693C3.36052 12.8286 3.55139 12.7495 3.75042 12.7495C3.94944 12.7495 4.14031 12.8286 4.28104 12.9693L9.00042 17.6896L20.4698 6.2193C20.6105 6.07857 20.8014 5.99951 21.0004 5.99951C21.1994 5.99951 21.3903 6.07857 21.531 6.2193C21.6718 6.36003 21.7508 6.55091 21.7508 6.74993C21.7508 6.94895 21.6718 7.13982 21.531 7.28055Z"
                    fill="#008D0E"
                />
            </svg>
        </div>
        <div
            class="step relative flex h-[36px] w-[225px] items-center justify-center text-[16px] text-[#555555]"
            :class="{
                'active-step': stepActive === INIT_STEP.COMPLETE,
                'finish-step': stepActive > INIT_STEP.COMPLETE,
            }"
        >
            3. {{ $t('kyc.complete') }}
            <svg
                v-if="stepActive > INIT_STEP.COMPLETE"
                class="ml-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.531 7.28055L9.53104 19.2806C9.46139 19.3503 9.37867 19.4056 9.28762 19.4433C9.19657 19.4811 9.09898 19.5005 9.00042 19.5005C8.90186 19.5005 8.80426 19.4811 8.71321 19.4433C8.62216 19.4056 8.53945 19.3503 8.46979 19.2806L3.21979 14.0306C3.07906 13.8898 3 13.699 3 13.4999C3 13.3009 3.07906 13.11 3.21979 12.9693C3.36052 12.8286 3.55139 12.7495 3.75042 12.7495C3.94944 12.7495 4.14031 12.8286 4.28104 12.9693L9.00042 17.6896L20.4698 6.2193C20.6105 6.07857 20.8014 5.99951 21.0004 5.99951C21.1994 5.99951 21.3903 6.07857 21.531 6.2193C21.6718 6.36003 21.7508 6.55091 21.7508 6.74993C21.7508 6.94895 21.6718 7.13982 21.531 7.28055Z"
                    fill="#008D0E"
                />
            </svg>
        </div>
    </div>

    <div
        class="mx-[-20px] flex h-[48px] items-center justify-between bg-[#FCFCFC] px-[20px] lg:hidden"
    >
        <div class="text-lg font-bold">
            {{ stepActive }}. {{ $t(INIT_STEP_NAME) }}
        </div>
        <div class="text-[14px] text-[#B01E46]">
            {{ stepActive }}/{{ Object.keys(INIT_STEP).length }}
        </div>
    </div>
</template>

<script setup lang="ts">
const { INIT_STEP, stepActive, INIT_STEP_NAME } = useSharedKyc()
</script>

<style scoped lang="scss">
.step {
    transition: 0.4 ease;
    overflow: hidden;
    &:hover {
        filter: brightness(1.2);
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 2px;
        width: 0%;
        left: 0;
        right: 0;
        transition: 0.4 ease;
        background-color: #b01e46;
    }
}
.active-step {
    color: #b01e46;
    font-weight: bold;
    transition: all 0.4s ease;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        transition: all 0.4s ease;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        background-color: #b01e46;
    }
}
.finish-step {
    color: #018a28;
}
</style>
