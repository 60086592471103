<template>
    <div
        class="step2 mx-auto flex max-w-[1024px] flex-col items-center gap-[12px] lg:flex-row"
    >
        <input
            ref="fileInput"
            type="file"
            multiple
            accept="image/*"
            class="hidden"
            @change="handleFileUpload"
        />

        <div
            v-for="(_, index) in step1Form.documentType === INIT_TYPE.ID
                ? 2
                : 1"
            :key="index"
            class="w-full"
            :class="[
                step1Form.documentType === INIT_TYPE.PASSPORT
                    ? 'flex justify-center'
                    : '',
            ]"
            @dragover.prevent
            @drop="(event) => handleDrop(event, index)"
        >
            <div
                v-if="
                    images[index] &&
                    (index === 0 ? !isLoadingLeft : !isLoadingRight)
                "
                class="relative mt-[24px] h-[55.814vw] max-h-[240px] w-[100%] lg:h-[292px] lg:max-h-[292px] lg:max-w-[500px]"
                :class="{
                    'bg-black': step1Form.documentType === INIT_TYPE.PASSPORT,
                }"
            >
                <img
                    class="h-[100%] w-[100%]"
                    :src="images[index]"
                    alt="image2"
                />
                <img
                    :src="`${
                        useRuntimeConfig().public.staticUrl
                    }/assets/img/kyc/close.svg`"
                    class="absolute right-[8px] top-[8px] h-[30px] w-[30px] cursor-pointer"
                    alt="icon"
                    @click="removeNthElementImmutable(index)"
                />
            </div>

            <div
                v-else
                class="upload-area mx-auto mt-[24px] h-[240px] w-full max-w-[500px] bg-[#FBFBFB] lg:h-[292px] lg:max-w-[640px]"
            >
                <div v-if="index === 0 ? !isLoadingLeft : !isLoadingRight">
                    <img
                        :src="`${
                            useRuntimeConfig().public.staticUrl
                        }/assets/img/kyc/${
                            step1Form.documentType === INIT_TYPE.ID
                                ? 'upload'
                                : 'passport'
                        }.svg`"
                        class="mx-auto mb-[12px] mt-[23px] h-[82px] w-[115px] lg:mt-[40px] lg:h-[110px] lg:w-[154px]"
                        alt="kyc"
                    />
                    <div
                        class="text-center text-[16px] font-bold lg:text-[18px]"
                    >
                        {{ getTitleCard(index) }}
                    </div>

                    <div
                        class="flex justify-center pb-[26px]"
                        @click="openFileInput(index)"
                    >
                        <button
                            :disabled="isLoadingRight || isLoadingLeft"
                            class="mt-[24px] h-[36px] w-[230px] rounded-[4px] border border-[#E4E4E6] bg-[#FFFFFF]"
                        >
                            {{ $t('kyc.upload') }}
                        </button>
                    </div>
                </div>

                <div v-else class="flex flex-col items-center justify-center">
                    <img
                        class="mt-[80px] h-[40px] w-[40px]"
                        :src="`${
                            useRuntimeConfig().public.staticUrl
                        }/assets/img/kyc/loading.svg`"
                        alt="icon"
                    />
                    <span class="mt-[31px] font-bold lg:mt-[10px]">
                        {{ $t('kyc.uploading') }}...</span
                    >
                    <div
                        v-if="index === 0 ? isLoadingLeft : isLoadingRight"
                        class="mx-auto mt-[20px] w-[90%] bg-[#EFEFEF] lg:mt-[40px]"
                    >
                        <div
                            class="progress-bar"
                            :style="{ width: progress + '%' }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="mx-auto mt-[48px] flex w-[100%] max-w-[500px] gap-[12px] lg:max-w-[640px]"
    >
        <button
            class="h-[52px] w-[100%] rounded lg:text-[18px] hover:lg:bg-slate-100"
            @click="stepActive = INIT_STEP.PERSONAL_INFO"
        >
            {{ $t('common.back') }}
        </button>
        <button
            class="h-[52px] w-[100%] rounded bg-[#F7D936] hover:bg-[#f7d936bf] lg:text-[18px]"
            :class="{
                '!pointer-events-none !bg-[#F4F4F4]': isDisabledBtn,
            }"
            @click="submitVerify"
        >
            {{ $t('kyc.continue') }}
        </button>
    </div>
</template>

<script setup>
import { useAlertStore } from '~/stores'
const alertStore = useAlertStore()
const { t } = useI18n({})
const isLeft = ref(false)
const {
    stepActive,
    INIT_STEP,
    images,
    submitVerify,
    step1Form,
    INIT_TYPE,
    isLoadingLeft,
    isLoadingRight,
} = useSharedKyc()
const progress = ref(0)

const fileInput = ref(null)

const openFileInput = (id) => {
    isLeft.value = id === 0
    fileInput.value.click()
}

const isDisabledBtn = computed(() => {
    return (
        (step1Form.value.documentType === INIT_TYPE.ID
            ? !images.value[0] || !images.value[1]
            : !images.value[0]) ||
        isLoadingLeft.value ||
        isLoadingRight.value
    )
})

const handleFileUpload = (event) => {
    processFiles(event.target.files)
    event.target.value = null
}

const handleDrop = (event, id) => {
    isLeft.value = id === 0
    event.preventDefault()
    processFiles(event.dataTransfer.files)
}

function removeNthElementImmutable(n) {
    images.value[n] = null
}

const getTitleCard = (item) => {
    if (step1Form.value.documentType === INIT_TYPE.ID) {
        return item === 0 ? t('kyc.front_side') : t('kyc.back_side')
    }
    return t('kyc.your_passport')
}

const processFiles = (files) => {
    const maxFileSize = 5 * 1024 * 1024 // 5Mb

    if (files.length > 1) {
        alertStore.showMessage({
            message: t('kyc.you_can_only'),
            type: 'ERROR',
        })
        return
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isLeft.value ? (isLoadingLeft.value = true) : (isLoadingRight.value = true)

    runProgress()

    setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isLeft.value
            ? (isLoadingLeft.value = false)
            : (isLoadingRight.value = false)
    }, 450)

    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file.size > maxFileSize) {
            alertStore.showMessage({
                message: t('kyc.file_size_exceeds'),
                type: 'ERROR',
            })
            continue
        }

        const reader = new FileReader()
        reader.onload = () => {
            if (isLeft.value) {
                images.value[0] = reader.result
            } else {
                images.value[1] = reader.result
            }
        }
        reader.readAsDataURL(file)
    }
}

const runProgress = () => {
    let startTime = null
    const duration = 1000

    const step = (timestamp) => {
        if (!startTime) startTime = timestamp
        const progressValue = Math.min(
            ((timestamp - startTime) / duration) * 100,
            100
        )
        progress.value = progressValue
        if (progressValue < 100) {
            requestAnimationFrame(step)
        }
    }

    requestAnimationFrame(step)
}
</script>

<style lang="scss" scoped>
.upload-area {
    border: 1px dashed #e4e4e6;
    border-radius: 4px;
}
.progress-bar {
    height: 2px;
    background-color: #f7d936;
}
</style>
