<template>
    <a
        class="relative flex w-full cursor-pointer items-center justify-start gap-2 rounded-md bg-[#B01E46] px-3 py-2 text-white"
        :href="`tel:${useRuntimeConfig().public.PHONE_CONTACT}`"
    >
        <div class="w-6">
            <img
                :src="`${
                    useRuntimeConfig().public.staticUrl
                }/assets/img/footer/phone.svg`"
                alt="phone"
            />
        </div>
        <div class="absolute left-1/2 -translate-x-1/2 text-sm font-medium">
            {{ $t('contact.phone') }}
        </div>
    </a>
</template>
<script setup></script>
