<template>
    <CommonModal :show="showSignUpModal" size="md" sticky @close="closeSelf">
        <template #header>
            <div
                class="relative flex items-end justify-center bg-white pb-[26px] pt-12 text-center"
            >
                <h3 class="-mb-1 mr-2 text-lg font-semibold text-black">
                    {{ $t('modal.signup.sign_up') }}
                </h3>
                <img :src="`/assets/logo_dark.svg`" alt="Logo" />
            </div>
        </template>
        <template #default>
            <div class="bg-white px-6 pb-7">
                <Veeform
                    :validation-schema="registerSchema"
                    @submit="handleRegister"
                >
                    <CommonTextInput
                        id="username"
                        type="text"
                        name="username"
                        :label="$t('modal.signup.username')"
                        is-required
                        :placeholder="$t('modal.signup.username_placeholder')"
                    />
                    <CommonPasswordInput
                        id="password"
                        type="password"
                        name="password"
                        :label="$t('modal.signup.password')"
                        is-required
                        :placeholder="$t('modal.signup.password_placeholder')"
                    />
                    <!-- <CommonTextInput
                        id="fullname"
                        type="text"
                        name="fullname"
                        :label="$t('modal.signup.fullname')"
                        is-required
                        :placeholder="$t('modal.signup.fullname_placeholder')"
                    /> -->
                    <CommonTextInput
                        id="referral_code"
                        type="text"
                        name="referral_code"
                        :value="referralCode"
                        :label="$t('modal.signup.referral_code')"
                        :placeholder="$t('modal.signup.enter_your_referral')"
                    />

                    <CommonPhoneInputFunc
                        type="tel"
                        name="phone"
                        classes="h-[46px] px-2"
                        :label="$t('modal.signup.phone')"
                        :placeholder="$t('modal.signup.phone_placeholder')"
                    />
                    <div class="flex justify-center py-4">
                        <button
                            type="submit"
                            class="w-full rounded-lg bg-bigg-yellow px-5 py-3 text-base font-medium text-black"
                            :disabled="loading"
                        >
                            {{ $t('modal.signup.sign_up_btn') }}
                        </button>
                    </div>
                </Veeform>
                <div class="flex justify-center text-xs font-normal">
                    <span class="mr-1 text-center text-[#757575]">
                        {{ $t('modal.signup.terms_1') }}
                        <span
                            class="cursor-pointer font-semibold text-[#212121] underline"
                            @click="handleTerms"
                        >
                            {{ $t('modal.signup.terms_2') }}
                        </span>
                    </span>
                </div>
                <div
                    class="flex justify-center pt-4 text-center text-[14px] font-normal"
                >
                    <div class="mr-1 text-[#757575]">
                        {{ $t('modal.signup.terms_4') }}
                    </div>

                    <div
                        class="cursor-pointer font-semibold text-bigg-red underline"
                        @click="handleLogin"
                    >
                        {{ $t('modal.login.login_btn') }}
                    </div>
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModal>
</template>

<script setup>
import { Form as Veeform } from 'vee-validate'
import { registerSchema } from '~/forms/registerSchema.schema'

import { storeToRefs } from 'pinia'
import { useModalStore, useUserStore } from '~/stores'

const useModalStoreInstance = useModalStore()
const { showSignUpModal, showLoginModal, closeRegistrationReason } =
    storeToRefs(useModalStoreInstance)
const closeSelf = () => {
    closeRegistrationReason.value = 'closeSelf'
    showSignUpModal.value = false
}
const route = useRoute()

const referralCode = ref('')

const getReferralCode = () => {
    if (route.query.ref && route.query.register) {
        referralCode.value = route.query.ref
    }
}

onMounted(() => {
    getReferralCode()
})

const useUserInstances = useUserStore()
const { register } = useUserInstances

const loading = ref(false)

const handleRegister = async (payload) => {
    const registerPayload = {
        username: payload.username,
        password: payload.password,
        fullname: payload.fullname,
        confirmPassword: payload.password,
        phone: payload.phone,
        ref_code: payload.referral_code,
    }
    try {
        loading.value = true
        closeRegistrationReason.value = 'registration form submission'
        await register(registerPayload)
    } catch (error) {
        console.error(error)
    } finally {
        loading.value = false
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'formSubmission',
            formType: 'Sign up',
        })
    }
}
const handleTerms = () => {
    closeRegistrationReason.value = 'handleTerms'
    showSignUpModal.value = false
    navigateTo('/help?tab=terms')
}
const handleLogin = () => {
    closeRegistrationReason.value = 'handleLogin'
    showSignUpModal.value = false
    showLoginModal.value = true
}
</script>
<style lang="scss" scoped>
@media (max-width: 1023px) {
    .group-input {
        background: #f6f7fa;
        > div > div {
            display: grid;
            grid-template-columns: 1fr 2.2fr 1fr;
            align-items: center;
            label {
                position: relative;
                order: 1;
                transform: unset;
                padding-left: 5px;
                top: 0;
                &:before {
                    content: unset;
                }
            }
            input {
                order: 2;
                background: none;
                border: none;
                text-align: right;
                padding: 0;
            }
        }
    }
}
:deep() {
    .m-input-wrapper-input {
        [type='text']:focus {
            --tw-ring-color: #fff;
        }
    }
    .m-country-selector {
        .m-select-list {
            min-width: 250px;
            max-width: 250px;
        }
    }
    .maz-rounded {
        border-radius: 6px;
    }
    .m-phone-number-input.--responsive {
        .m-country-selector__select {
            max-width: 86px;
        }
    }
    .m-country-selector__country-flag {
        &.--should-have-bottom-flag {
            bottom: 6px;
        }
    }
    .m-input {
        &.--should-up {
            &.--has-label {
                &.m-select-input {
                    .m-input-label {
                        display: none;
                    }
                }
            }
        }
    }
    .m-input.--has-label .m-input-label {
        font-size: 0.875rem;
    }
    .maz-border-primary {
        border-color: rgb(247 217 54 / 1);
    }
    .m-phone-number-input.--responsive {
        flex-direction: row;
        width: 100%;
    }
    .m-input.--md {
        height: 42px;
    }
    .m-phone-number-input.--responsive {
        .m-country-selector {
            max-width: 86px;
            min-width: 86px;
        }
        .m-phone-input {
            margin-top: 0;
            flex: 1;
        }
    }
    .m-input-wrapper-right {
        padding-right: 0;
    }
    .m-phone-number-input.--responsive {
        .m-phone-input.--border-radius {
            .m-input-wrapper {
                border-radius: 0 6px 6px 0;
            }
        }
    }
    .m-phone-number-input.--responsive {
        .m-country-selector__select {
            .m-select-input {
                .m-input-wrapper {
                    border-radius: 6px 0 0 6px;
                }
            }
            .m-input-wrapper.--default-border.maz-rounded {
                border-right-width: 0;
            }
        }
    }
    @media (min-width: 425px) {
        .m-phone-number-input.--responsive {
            width: 100%;
        }
        .m-input.--block {
            height: 42px;
        }
        .m-phone-number-input.--responsive {
            .m-country-selector__select {
                .m-select-input {
                    .m-input-wrapper {
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 6px;
                        height: 42px;
                    }
                }
            }
        }
        .m-phone-number-input.--responsive .m-phone-input {
            height: 42px;
            &.--border-radius {
                .m-input-wrapper {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }

    .m-country-selector:not(.--no-flags) {
        .m-country-selector__select {
            .m-select-input input {
                padding: 0.625rem 0;
            }
        }
    }
    .m-select-chevron {
        display: none;
    }
    .m-input.--has-label {
        .m-input-input {
            padding: 1rem 0.75rem 0.25rem 0.75rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: #000;
        }
    }
    @media (max-width: 375px) {
        .m-phone-number-input.--responsive .m-country-selector {
            max-width: 72px;
            min-width: 72px;
        }
        .m-phone-number-input.--responsive .m-country-selector__select {
            max-width: 72px;
            .m-select-input {
                input {
                    padding-left: 2rem !important;
                }
            }
        }
    }
}
</style>
