<template>
    <CommonModal :show="showLoginModal" size="md" sticky @close="closeSelf">
        <template #header>
            <div
                class="relative flex items-end justify-center bg-white pb-[26px] pt-12 text-center"
            >
                <h3 class="-mb-1 mr-2 text-lg font-semibold text-black">
                    {{ $t('modal.login.login') }}
                </h3>
                <img :src="`/assets/logo_dark.svg`" alt="Logo" />
            </div>
        </template>
        <template #default>
            <div class="bg-white px-6 pb-7">
                <Veeform :validation-schema="loginSchema" @submit="handleLogin">
                    <CommonTextInput
                        id="username"
                        type="text"
                        name="username"
                        :label="$t('modal.login.username')"
                        :placeholder="$t('modal.login.username_placeholder')"
                        :value="usernameDefault"
                    />
                    <CommonPasswordInput
                        id="password"
                        type="password"
                        name="password"
                        :label="$t('modal.login.password')"
                        :placeholder="$t('modal.login.password_placeholder')"
                    />
                    <div class="flex justify-center py-4">
                        <button
                            type="submit"
                            class="w-full rounded-lg bg-bigg-yellow px-5 py-3 text-base font-medium text-black"
                            :disabled="isLoading"
                        >
                            {{ $t('modal.login.login_btn') }}
                        </button>
                    </div>
                </Veeform>
                <div class="flex justify-center text-xs font-normal">
                    <div
                        class="cursor-pointer font-semibold text-[#212121] underline"
                        @click="onForgotPassword"
                    >
                        {{ $t('modal.login.forgot_password') }}
                    </div>
                </div>

                <div
                    class="flex justify-center pt-4 text-center text-[14px] font-normal"
                >
                    <span class="mr-1 text-[#757575]">
                        {{ $t('modal.login.has_register') }}
                    </span>
                    <span
                        class="cursor-pointer font-semibold text-bigg-red underline"
                        @click="openRegister"
                        >{{ $t('modal.signup.sign_up_btn') }}</span
                    >
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModal>
</template>
<script setup>
import { Form as Veeform } from 'vee-validate'
import { loginSchema } from '~/forms/loginSchema.schema'
import { storeToRefs } from 'pinia'
import { useModalStore, useUserStore } from '~/stores'
const useModalStoreInstance = useModalStore()
const { showLoginModal, showSignUpModal, showForgotPasswordModal } =
    storeToRefs(useModalStoreInstance)

const saveAccountDefault = ref(false)
const usernameDefault = ref('')

const useUserInstances = useUserStore()
const { login } = useUserInstances

const router = useRouter()
const route = useRoute()

const isLoading = ref(false)

const closeSelf = () => {
    showLoginModal.value = false
}

const openRegister = () => {
    closeSelf()
    showSignUpModal.value = true
}

const onForgotPassword = () => {
    showLoginModal.value = false
    showForgotPasswordModal.value = true
}

const handleLogin = async (payload) => {
    try {
        isLoading.value = true
        await login(payload, (user) => {
            if (user.balance === 0) {
                router.push('/account/deposit')
            } else {
                if (route.path === '/setting') {
                    router.push('/account')
                }
            }
            closeSelf()
        })
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

watch(
    () => showLoginModal.value,

    (newVal, _) => {
        if (newVal) {
            const saveAccount = localStorage.getItem('saveAccount')
            if (saveAccount === '1') {
                saveAccountDefault.value = true
                usernameDefault.value = localStorage.getItem('username')
            } else {
                saveAccountDefault.value = false
                usernameDefault.value = ''
            }
        }
    }
)
</script>
