<template>
    <div class="flex flex-col gap-4">
        <div>
            <div class="mb-3 mt-8 text-sm">
                {{ $t('kyc.document_type') }}
            </div>

            <div class="flex items-center gap-6">
                <div
                    class="flex items-center gap-2"
                    @click="step1Form.documentType = INIT_TYPE.ID"
                >
                    <div
                        class="select h-[19.5px] w-[19.5px]"
                        :class="{
                            'select-active':
                                step1Form.documentType === INIT_TYPE.ID,
                        }"
                    ></div>
                    <div class="text-sm text-grey-600">
                        {{ $t('kyc.id') }}
                    </div>
                </div>

                <div
                    class="flex items-center gap-2"
                    @click="step1Form.documentType = INIT_TYPE.PASSPORT"
                >
                    <div
                        class="select h-[19.5px] w-[19.5px]"
                        :class="{
                            'select-active':
                                step1Form.documentType === INIT_TYPE.PASSPORT,
                        }"
                    ></div>
                    <div class="text-sm text-grey-600">
                        {{ $t('kyc.passport') }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="mb-2 text-sm">
                {{
                    step1Form.documentType === INIT_TYPE.ID
                        ? $t('kyc.identification_number')
                        : $t('kyc.passport_number')
                }}
            </div>
            <CommonInput
                v-model="step1Form.identificationNumber"
                :type="
                    step1Form.documentType === INIT_TYPE.ID ? 'text' : 'text'
                "
                name="number"
                :class-custom="`lg:h-[44px] h-[42px]`"
                :placeholder="$t('kyc.enter_id_number')"
                @input="handleChange"
            />
        </div>
        <div
            :class="{
                'pointer-events-none': !!bankActive,
            }"
        >
            <div class="mb-2 text-sm">
                {{ $t('kyc.full_name') }}
            </div>
            <CommonInput
                v-model="step1Form.fullName"
                type="text"
                name="fullname"
                :class-custom="`lg:h-[44px] h-[42px] ${
                    bankActive ? 'bg-[#E4E4E6]' : ''
                }`"
                :placeholder="$t('kyc.enter_full_name')"
                @input="changeFullname"
            />
        </div>

        <div>
            <div class="mb-2 text-sm">
                {{ $t('kyc.date_of_birth') }}
            </div>

            <div class="flex gap-2">
                <ModalKycSelect
                    v-model="step1Form.dateOfBirth.days"
                    :class="{ 'border-[#ff1616c5]': isShowMessError }"
                    :title="$t('kyc.day')"
                    :list="Array.from({ length: 31 }, (_, i) => i + 1)"
                />
                <ModalKycSelect
                    v-model="step1Form.dateOfBirth.months"
                    :class="{ 'border-[#ff1616c5]': isShowMessError }"
                    :list="Array.from({ length: 12 }, (_, i) => i + 1)"
                    :title="$t('kyc.month')"
                />
                <ModalKycSelect
                    v-model="step1Form.dateOfBirth.years"
                    :class="{ 'border-[#ff1616c5]': isShowMessError }"
                    :title="$t('kyc.year')"
                    :list="
                        Array.from(
                            { length: 100 },
                            (_, i) => new Date().getFullYear() - i
                        )
                    "
                />
            </div>

            <div v-if="isShowMessError" class="text-[12px] text-[#ff1616c5]">
                {{ $t('kyc.date') }}
            </div>
        </div>
        <div>
            <div class="mb-2 text-sm">
                {{ $t('kyc.gender') }}
            </div>
            <ModalKycSelectObject
                v-model="step1Form.gender"
                :title="$t('kyc.choose_gender')"
                :list="[
                    { title: $t('kyc.male'), gender: 'MALE' },
                    { title: $t('kyc.female'), gender: 'FEMALE' },
                    { title: $t('kyc.other'), gender: 'OTHER' },
                ]"
            />
        </div>

        <div>
            <button
                class="mt-[30px] h-[48px] w-[100%] rounded-sm bg-[#F7D936] lg:mt-[10px] lg:h-[52px] lg:hover:bg-[#f7da36d8]"
                :class="{
                    '!pointer-events-none !bg-[#F4F4F4] text-[#AAAAAA]':
                        !meta.valid ||
                        isShowMessError ||
                        !step1Form.gender ||
                        !step1Form.dateOfBirth.months ||
                        !step1Form.dateOfBirth.days ||
                        !step1Form.dateOfBirth.years,
                }"
                @click="stepActive = INIT_STEP.UPLOAD_DOCUMENT"
            >
                {{ $t('kyc.continue') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { useForm } from 'vee-validate'
import { object } from 'yup'
import * as Yup from 'yup'
const { meta } = useForm({
    validationSchema: object({
        number: Yup.string()
            .min(1, 'error.Required')
            .max(20, 'error.max_20_character'),
        fullname: Yup.string()
            .required('error.Required')
            .max(50, 'error.max_50_character'),
    }),
})

const { step1Form, INIT_TYPE, bankActive, INIT_STEP, stepActive } =
    useSharedKyc()

function isValidDate(day, month, year) {
    const date = new Date(year, month - 1, day)
    return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
    )
}

const handleChange = () => {
    if (step1Form.value.documentType === INIT_TYPE.ID) {
        const value = event.target.value
        if (/^\d*$/.test(value)) {
            step1Form.value.identificationNumber = value
        } else {
            step1Form.value.identificationNumber = value.replace(/\D/g, '')
        }
    }
}

const changeFullname = (event) => {
    const value = event.target.value
    if (/^[\u0E00-\u0E7Fa-zA-Z\s]*$/.test(value)) {
        step1Form.value.fullName = value
    } else {
        step1Form.value.fullName = value.replace(
            /[^\u0E00-\u0E7Fa-zA-Z\s]/g,
            ''
        )
    }
}
const isShowMessError = computed(() => {
    const { months, days, years } = step1Form.value.dateOfBirth
    return (
        !isValidDate(Number(days), Number(months), Number(years)) &&
        !!months &&
        !!days &&
        !!years
    )
})

watch(
    () => step1Form.value.documentType,
    // eslint-disable-next-line
    (newVal, _) => {
        step1Form.value.identificationNumber = ''
    }
)
</script>

<style lang="scss" scoped>
.select {
    border-radius: 50%;
    border: 2.25px solid #e4e4e6;
}
.select-active {
    border: 2.25px solid #f7d936;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        inset: 2px;
        border-radius: 50%;
        background: #f7d936;
    }
}
</style>
