<template>
    <CommonModalFunction
        :title="$t('footer.functions.funds')"
        :show="showFundsFunctionModal"
        @close="closeSelf"
    >
        <template #left-button>
            <CommonIconButton
                icon="deposit.svg"
                :content="$t('supports.deposit')"
                @onclick="
                    () => {
                        closeSelf()
                        $router.push('/account/deposit')
                    }
                "
            />
        </template>
        <template #right-button>
            <CommonIconButton
                icon="withdraw.svg"
                :content="$t('supports.withdrawal')"
                classes="!bg-[color:#B01E46] text-white"
                @onclick="
                    () => {
                        closeSelf()
                        $router.push('/account/withdraw')
                    }
                "
            />
        </template>
    </CommonModalFunction>
</template>
<script setup>
import { useModalStore } from '~/stores'
import { storeToRefs } from 'pinia'
const useModalStoreInstance = useModalStore()
const { showFundsFunctionModal } = storeToRefs(useModalStoreInstance)

const closeSelf = () => {
    showFundsFunctionModal.value = false
}
</script>
