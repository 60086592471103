<template>
    <ul
        class="grid w-full grid-cols-5 items-center justify-evenly border-t bg-white px-1.5 py-3.5"
    >
        <li v-for="item in NavbarItems" :key="item.id" class="max-w-[98px]">
            <layout-mobile-bottom-navbar-item :item="item" />
        </li>
    </ul>
</template>
<script setup>
import { useUserStore, useModalStore } from '~/stores'
import { storeToRefs } from 'pinia'
const useUserStoreInstance = useUserStore()
const { isLoggedIn } = storeToRefs(useUserStoreInstance)
const staticUrl = useRuntimeConfig().public.staticUrl
const router = useRouter()
const useModalStoreInstance = useModalStore()
const { showFundsFunctionModal, showSupportFunctionModal } = storeToRefs(
    useModalStoreInstance
)

const openSupportTooltip = () => (showSupportFunctionModal.value = true)

const openShowFundsFunctionModal = () => (showFundsFunctionModal.value = true)

const openSettingPage = () => {
    if (isLoggedIn.value) {
        router.push('/account')
    } else {
        router.push('/setting')
    }
}

const NavbarItems = [
    {
        name: 'home',
        i18_key: 'footer.nav_menu.home',
        key: 'home',
        id: 'home',
        url: '/',
        icon: `${staticUrl}/assets/img/icons/check.svg`,
        action: () => navigateTo('/'),
    },
    {
        name: 'promotion',
        i18_key: 'sidebar.promotion',
        key: 'promotion',
        id: 'promotion',
        url: '/promotions',
        icon: `${staticUrl}/assets/img/icons/promotion.svg`,
        action: () => navigateTo('/promotions'),
    },

    {
        name: 'deposit',
        i18_key: 'footer.nav_menu.deposit',
        key: 'deposit',
        id: 'deposit',
        url: '/account/deposit',
        icon: `${staticUrl}/assets/img/icons/check.svg`,
        action: () => openShowFundsFunctionModal(),
    },
    {
        name: 'support',
        i18_key: 'footer.nav_menu.support',
        key: 'support',
        id: 'support',
        url: '',
        icon: `${staticUrl}/assets/img/icons/support.svg`,
        action: () => openSupportTooltip(),
    },
    {
        name: 'setting',
        i18_key: 'footer.nav_menu.account',
        key: 'setting',
        id: 'setting',
        url: '/account',
        icon: `${staticUrl}/assets/img/icons/setting.svg`,
        action: () => openSettingPage(),
    },
]
</script>
