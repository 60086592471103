<template>
    <div
        class="relative flex h-[44px] w-[100%] items-center justify-center pl-[20px] lg:h-[72px] lg:justify-start lg:gap-[10px]"
    >
        <button
            class="absolute left-0 flex items-center gap-[11px] lg:relative"
            :class="{
                'pointer-events-none opacity-50': isLoadingComponent,
            }"
            @click="closeModal()"
        >
            <svg
                class="hidden lg:block"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                    fill="#2A2A2A"
                />
            </svg>
            <svg
                class="lg:hidden"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.0006 8.00042C18.0006 8.19933 17.9216 8.3901 17.7809 8.53075C17.6403 8.6714 17.4495 8.75042 17.2506 8.75042H2.5609L8.03122 14.2198C8.1009 14.2895 8.15617 14.3722 8.19389 14.4632C8.2316 14.5543 8.25101 14.6519 8.25101 14.7504C8.25101 14.849 8.2316 14.9465 8.19389 15.0376C8.15617 15.1286 8.1009 15.2114 8.03122 15.281C7.96153 15.3507 7.87881 15.406 7.78776 15.4437C7.69672 15.4814 7.59914 15.5008 7.50059 15.5008C7.40204 15.5008 7.30446 15.4814 7.21342 15.4437C7.12237 15.406 7.03965 15.3507 6.96996 15.281L0.219965 8.53104C0.150233 8.46139 0.0949134 8.37867 0.0571702 8.28762C0.019427 8.19657 0 8.09898 0 8.00042C0 7.90186 0.019427 7.80426 0.0571702 7.71321C0.0949134 7.62216 0.150233 7.53945 0.219965 7.46979L6.96996 0.719792C7.1107 0.579062 7.30157 0.5 7.50059 0.5C7.69961 0.5 7.89048 0.579062 8.03122 0.719792C8.17195 0.860523 8.25101 1.05139 8.25101 1.25042C8.25101 1.44944 8.17195 1.64031 8.03122 1.78104L2.5609 7.25042H17.2506C17.4495 7.25042 17.6403 7.32943 17.7809 7.47009C17.9216 7.61074 18.0006 7.8015 18.0006 8.00042Z"
                    fill="#2A2A2A"
                />
            </svg>
        </button>
        <div class="text-[16px] font-bold">
            {{ $t('kyc.kyc_verification') }}
        </div>
    </div>
</template>

<script setup>
const { closeModal, isLoadingComponent } = useSharedKyc()
</script>

<style lang="scss" scoped></style>
