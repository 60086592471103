<template>
    <div>
        <ul
            class="mx-[24px] mb-[30px] grid grid-cols-2 space-x-2 rounded-full border border-[#EFEFEF] bg-bigg-gray text-sm font-medium"
            role="tablist"
        >
            <li>
                <button
                    :aria-selected="tabType === types[0]"
                    class="inline-block h-full w-full rounded-full py-[10px] text-[12px] text-neutral-500 aria-selected:bg-bigg-yellow aria-selected:text-black max-[600px]:text-[11px]"
                    @click="tabType = types[0]"
                >
                    {{ $t('modal.forgot_password.reset_by_email') }}
                </button>
            </li>
            <!-- <li>
                <button
                    :aria-selected="tabType === types[1]"
                    class="inline-block w-full rounded-full py-[10px] text-[12px] text-neutral-500 aria-selected:bg-bigg-yellow aria-selected:text-black max-[600px]:text-[11px]"
                    @click="tabType = types[1]"
                >
                    {{ $t('modal.forgot_password.reset_by_line') }}
                </button>
            </li> -->
            <li>
                <button
                    :aria-selected="tabType === types[2]"
                    class="inline-block w-full rounded-full py-[10px] text-[12px] text-neutral-500 aria-selected:bg-bigg-yellow aria-selected:text-black max-[600px]:text-[11px]"
                    @click="tabType = types[2]"
                >
                    {{ $t('modal.forgot_password.reset_by_phone') }}
                </button>
            </li>
        </ul>
        <component :is="componentTypes[tabType]" @submitted="handleSubmit" />
    </div>
</template>
<script setup>
const componentTypes = ref({
    ['email']: resolveComponent('ModalForgotPasswordByEmail'),
    ['line']: resolveComponent('ModalForgotPasswordByLine'),
    ['phone']: resolveComponent('ModalForgotPasswordByPhone'),
})

const types = ['email', 'line', 'phone']
const tabType = ref(types[0])
const emit = defineEmits(['next'])

const handleSubmit = () => {
    emit('next')
}
</script>
