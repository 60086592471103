<template>
    <CommonModal :show="showVerifyOTPModal" size="md" sticky @close="closeSelf">
        <template #header>
            <div class="relative bg-white pt-12 text-center">
                <h3 class="text-xl font-semibold text-black">
                    {{ $t('modal.signup.sign_up') }}
                    {{ useRuntimeConfig().public.BRAND_NAME }}
                </h3>
                <p class="mt-2 px-7 text-sm font-normal text-[#737373]">
                    {{ $t('modal.signup.verify_otp') }}
                </p>
            </div>
        </template>

        <template #default>
            <div class="flex flex-col bg-white px-2 pb-12 pt-8 xs:px-8">
                <v-otp-input
                    ref="otpInput"
                    v-model:value="valueOtp"
                    class="modal-verify-otp"
                    :input-classes="['base-input']"
                    separator=""
                    :num-inputs="inputLength"
                    :should-auto-focus="true"
                    input-type="number"
                />
                <div class="mt-8 flex justify-center">
                    <button
                        type="button"
                        class="mx-2 w-full rounded-[4px] bg-bigg-yellow px-5 py-3 text-base font-medium text-black md:w-40"
                        :disabled="loading"
                        @click="handleVerify"
                    >
                        {{ $t('modal.signup.verify') }}
                    </button>
                </div>
                <div
                    class="mt-3 text-center text-xs font-normal text-[#737373]"
                >
                    <span>
                        {{ $t('modal.email.did_not_recive') }}
                    </span>
                    <span class="ml-1">
                        {{ $t('modal.email.resend_otp') }}
                    </span>
                    <span v-if="seconds > 0"> ({{ seconds }}) </span>
                </div>
                <div
                    class="mt-6 flex justify-center px-6 text-center align-middle text-xs font-normal"
                >
                    <div
                        class="cursor-pointer text-bigg-red underline"
                        @click="handleResendOTP"
                    >
                        {{ $t('modal.email.resend_otp') }}
                    </div>
                </div>
            </div>
        </template>

        <template #footer> </template>
    </CommonModal>
</template>

<script setup>
import VOtpInput from 'vue3-otp-input'
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
const useModalStoreInstance = useModalStore()
const { showVerifyOTPModal } = storeToRefs(useModalStoreInstance)

const inputLength = 6
const otpInput = ref(null)
const valueOtp = ref('')

const seconds = ref(60)
let timer = null

const closeSelf = () => {
    showVerifyOTPModal.value = false
}

const handleResendOTP = () => {}

const startTimer = () => {
    timer = setInterval(() => {
        if (seconds.value > 0) {
            seconds.value--
        } else if (seconds.value === 0) {
            clearInterval(timer)
            closeSelf()
        } else {
            closeSelf()
        }
    }, 1000)
}

onMounted(() => {
    startTimer()
})

onBeforeUnmount(() => {
    clearInterval(timer)
})
</script>

<style lang="scss" scoped>
:deep() {
    .modal-verify-otp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        width: 100%;
        height: 56px;
        & > div {
            flex: 1;
            .base-input {
                aspect-ratio: 1;
                width: 100%;
                max-width: 60px;
                border-radius: 4px;
                border: 1px solid #f1f1f1;
                font-weight: 700;
                font-size: 24px;
                line-height: calc(30 / 24);
                text-align: center;
                &:focus {
                    border-color: rgb(247 217 54 / 1);
                    box-shadow: none;
                }
            }
        }
    }
}
</style>
