<template>
    <CommonModalAlert
        :show="showKycRequired"
        size="sm"
        sticky
        :class-custom="'!w-[504px] !max-w-[504px]'"
        @close="closeSelf"
    >
        <template #header> </template>
        <template #default>
            <div
                class="flex flex-col items-center justify-center space-y-5 rounded-[12px] bg-[#FAFAFA] p-[24px] py-12 font-roboto text-sm font-medium"
            >
                <div class="min-h-[96px]">
                    <img
                        :src="`${staticUrl}/assets/img/kyc/verify.svg`"
                        alt="verify"
                    />
                </div>
                <div
                    class="text-[22px] font-[600] leading-[28px] text-[#1A181B]"
                >
                    {{ $t('kyc.please_verify') }}
                </div>

                <div class="text-[16px] leading-[24px] text-[#59565A]">
                    {{ $t('kyc.please_verify_your') }}
                </div>

                <div class="flex w-full gap-[10px]">
                    <button
                        class="h-[44px] w-1/2 rounded-md bg-[#F7D936] text-[14px]"
                        @click="showModalVerify"
                    >
                        {{ $t('kyc.verify_account') }}
                    </button>
                    <button
                        class="h-[44px] w-1/2 rounded-md bg-[#F3F0F4] text-[14px]"
                        @click="closeSelf"
                    >
                        {{ $t('common.back') }}
                    </button>
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModalAlert>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
const useUserStoreInstance = useUserStore()
const { user } = storeToRefs(useUserStoreInstance)
const router = useRouter()
const useModalStoreInstance = useModalStore()

const { showKycRequired } = storeToRefs(useModalStoreInstance)
const staticUrl = useRuntimeConfig().public.staticUrl
const closeSelf = () => {
    showKycRequired.value = false
}

const showModalVerify = () => {
    closeSelf()

    if (user.value.kyc_status === 'NEW') {
        router.push('/account/deposit')
        return
    }
    setTimeout(() => {
        useSharedKyc().openModal()
    }, 200)
}
</script>
