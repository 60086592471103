<template>
    <div
        v-show="alert"
        id="toast"
        class="toast fixed left-1/2 top-12 z-[100] flex w-max max-w-[390px] -translate-x-1/2 items-center rounded-[4px] bg-white p-4 text-black shadow-alert lg:max-w-md"
        role="alert"
    >
        <!-- SUCCESS -->
        <div
            v-if="type === 'SUCCESS'"
            class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200"
        >
            <svg
                class="h-5 w-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
            >
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
                />
            </svg>
            <span class="sr-only">Check icon</span>
        </div>

        <!-- ERROR -->
        <div
            v-if="type === 'ERROR'"
            class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg text-red-500 dark:bg-red-800 dark:text-red-200"
        >
            <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="18" cy="18" r="18" fill="#FFDDDD" />
                <path d="M12 12L24 24" stroke="#FF2F2F" stroke-width="2" />
                <path d="M24 12L12 24" stroke="#FF2F2F" stroke-width="2" />
            </svg>
            <span class="sr-only">Error icon</span>
        </div>

        <!-- WARNING -->
        <div
            v-if="type === 'WARNING'"
            class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200"
        >
            <svg
                class="h-5 w-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
            >
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"
                />
            </svg>
            <span class="sr-only">Warning icon</span>
        </div>
        <div class="ml-3 text-sm font-normal">
            {{ message_key ? $t(message_key) : message }}
        </div>
        <button
            type="button"
            class="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1.5 text-grey-400"
            aria-label="Close"
            @click="closeToast"
        >
            <span class="sr-only">Close</span>
            <svg
                class="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
            >
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
            </svg>
        </button>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import { useAlertStore } from '~/stores'

const alertStore = useAlertStore()
const { type, message, alert, message_key } = storeToRefs(alertStore)

const closeToast = () => {
    alertStore.clear()
}
</script>
<style scoped lang="scss">
::v-deep(.shadow-alert) {
    box-shadow: 0px 5px 15px 0px #00000026;
}
</style>
