import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'
export const usePromotionStore = defineStore('promotionStore', () => {
    const fetcher = useCustomFetch()

    const cancelPromotion = async () => {
        return await fetcher.post('/payment/cancelpromotion')
    }

    return {
        cancelPromotion,
    }
})
