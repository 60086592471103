<template>
    <div class="relative w-[100%]">
        <div
            ref="ignoreElRef"
            class="relative h-[42px] rounded-[4px] border border-[#E4E4E6] lg:h-[44px]"
            :class="{
                'border-[#F7D936]': isShowSelect,
            }"
            v-bind="$attrs"
            @click="toggleSelect"
        >
            <div
                class="select-dropdown absolute inset-0 flex items-center justify-end pr-[8px]"
            >
                <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.6873 7.14828L10.0623 12.7733C10.0101 12.8256 9.94807 12.8671 9.87978 12.8954C9.81149 12.9237 9.7383 12.9383 9.66438 12.9383C9.59045 12.9383 9.51726 12.9237 9.44897 12.8954C9.38068 12.8671 9.31865 12.8256 9.26641 12.7733L3.64141 7.14828C3.53586 7.04273 3.47656 6.89958 3.47656 6.75031C3.47656 6.60105 3.53586 6.45789 3.64141 6.35234C3.74695 6.2468 3.89011 6.1875 4.03938 6.1875C4.18864 6.1875 4.3318 6.2468 4.43734 6.35234L9.66438 11.5801L14.8914 6.35234C14.9437 6.30008 15.0057 6.25863 15.074 6.23034C15.1423 6.20206 15.2155 6.1875 15.2894 6.1875C15.3633 6.1875 15.4365 6.20206 15.5048 6.23034C15.573 6.25863 15.6351 6.30008 15.6873 6.35234C15.7396 6.40461 15.7811 6.46665 15.8093 6.53493C15.8376 6.60322 15.8522 6.6764 15.8522 6.75031C15.8522 6.82422 15.8376 6.89741 15.8093 6.96569C15.7811 7.03398 15.7396 7.09602 15.6873 7.14828Z"
                        fill="#666666"
                    />
                </svg>
            </div>
            <div>
                <span
                    v-if="model"
                    class="absolute inset-0 flex items-center bg-transparent pl-[8px] text-[14px] text-[#000000]"
                    >{{ model }}</span
                >
                <span
                    v-else
                    class="absolute inset-0 flex items-center bg-transparent pl-[8px] text-[14px] text-[#aaaaaa]"
                    >{{ title }}</span
                >
            </div>
        </div>
        <div
            v-if="isShowSelect"
            v-on-click-outside="onClickOutsideHandler"
            class="absolute left-0 right-0 top-[45px] z-[3] max-h-[200px] min-h-[100px] overflow-y-scroll rounded-md border border-[#E4E4E6] bg-white"
        >
            <div
                v-for="(item, index) in list"
                :key="index"
                :class="[
                    'flex h-[35px] items-center px-2 hover:bg-slate-100',
                    {
                        'bg-slate-100': model === item,
                    },
                ]"
                @click="
                    () => {
                        isShowSelect = false
                        model = item
                    }
                "
            >
                <span class="text-[14px]"> {{ item }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
const { title, list } = defineProps({
    title: String,
    list: Array,
})
const model = defineModel()
const isShowSelect = ref(false)

const ignoreElRef = ref()

const toggleSelect = () => {
    isShowSelect.value = !isShowSelect.value
}
const onClickOutsideHandler = [
    () => {
        isShowSelect.value = false
    },
    { ignore: [ignoreElRef] },
]
</script>

<style lang="scss" scoped></style>
