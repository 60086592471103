<template>
    <div
        class="mx-auto flex max-w-[480px] flex-col items-center justify-center"
    >
        <img
            class="mt-[20px] lg:mt-[80px]"
            :src="`${staticUrl}/assets/img/kyc/completed.svg`"
            alt="completed"
        />
        <div class="my-[24px] text-[20px] font-bold">
            {{ $t('kyc.verification_completed') }}
        </div>
        <div class="text-center">{{ $t('kyc.your_identify') }}</div>
        <button
            class="mt-[40px] h-[52px] w-full rounded bg-[#F7D936] text-[18px]"
            @click="closeModal()"
        >
            OK
        </button>
    </div>
</template>

<script setup>
const { closeModal } = useSharedKyc()
const staticUrl = useRuntimeConfig().public.staticUrl
</script>

<style lang="scss" scoped></style>
