import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'
import { useAlertStore } from '~/stores'

export const useResetPassStore = defineStore('resetPassStore', () => {
    const fetcher = useCustomFetch()

    const otpCountdownInterval = ref(null)
    const otpCountdown = ref(0)
    const submittedOTP = ref('')
    const submittedOTPByPhone = ref(false)
    const submitOTPError = ref(null)

    const submittedUsername = ref('')
    const submitUsernameError = ref(null)

    const submittedEmail = ref('')
    const submitEmailError = ref(null)

    const submittedPhone = ref('')
    const submitPhoneError = ref(null)

    const submitByPhoneError = ref(null)

    const alertStore = useAlertStore()

    const resetSendOtp = () => {
        submittedEmail.value = null
        submitEmailError.value = null
        submittedUsername.value = null
        submitUsernameError.value = null
        submittedPhone.value = null
        submitPhoneError.value = null
        submitByPhoneError.value = null
        submittedOTPByPhone.value = false
        submittedOTP.value = null
        submitOTPError.value = null
        otpCountdown.value = 0
        clearInterval(otpCountdownInterval.value)
    }

    const startOtpCountdown = () => {
        otpCountdown.value = 60
        otpCountdownInterval.value = setInterval(() => {
            otpCountdown.value--
            if (otpCountdown.value === 0) {
                clearInterval(otpCountdownInterval.value)
            }
        }, 1000)
    }

    const sendOtp = async (queryParams) => {
        try {
            resetSendOtp()
            const { data } = await fetcher.get('/user/sendOtp', queryParams)
            if (data.value.status === 'OK') {
                queryParams.email && (submittedEmail.value = queryParams.email)
                queryParams.username &&
                    (submittedUsername.value = queryParams.username)
                queryParams.phone && (submittedPhone.value = queryParams.phone)
                startOtpCountdown()
            } else {
                queryParams.email &&
                    (submitEmailError.value = data.value.message)
                data.value.message &&
                    (submitUsernameError.value = data.value.message)
                data.value.message &&
                    (submitPhoneError.value = data.value.message)
            }
        } catch (error) {
            resetSendOtp()
            // will be catched later
            throw error
        }
    }

    const verifyOtp = async (payload) => {
        try {
            submitOTPError.value = null
            const { data } = await fetcher.get('/user/verifyOtp', payload) // todo change endpoint
            if (data.value.status === 'OK') {
                submittedOTP.value = payload.otp
            } else {
                submitOTPError.value = data.value.message
            }
        } catch (error) {
            submittedOTP.value = null
            throw error
        }
    }

    const verifyByPhone = async (payload) => {
        try {
            submitByPhoneError.value = null
            const { data } = await fetcher.get('/user/sendSmsOtp', payload)

            if (data.value.status === 'OK') {
                submittedOTPByPhone.value = true
                submittedPhone.value = payload.phone
                startOtpCountdown()
            } else {
                submitByPhoneError.value = data.value.message
                alertStore.showMessage({
                    message: data.value.message,
                    type: 'ERROR',
                })
            }
        } catch (error) {
            alertStore.showMessageError(error)
            throw error
        }
    }

    const resetPasswordByOtp = async (payload) => {
        return await fetcher.post('/user/resetPasswordByOtp', payload)
    }

    const resetPasswordByPhone = async (payload) => {
        return await fetcher.post('/user/resetPasswordByPhone', payload)
    }
    return {
        submittedUsername,
        submitUsernameError,
        submittedEmail,
        submitEmailError,
        submittedPhone,
        submitPhoneError,
        submittedOTP,
        submitOTPError,
        otpCountdown,
        submittedOTPByPhone,
        submitByPhoneError,
        sendOtp,
        verifyOtp,
        resetPasswordByOtp,
        verifyByPhone,
        resetPasswordByPhone,
    }
})
