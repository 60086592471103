<template>
    <div class="w-200 fixed right-0 top-96 z-30 hidden lg:block">
        <a
            :href="lineContact"
            target="_blank"
            class="mb-1 inline-block hover:ring-opacity-90"
        >
            <img
                :src="`${staticUrl}/assets/img/hot-line/line.svg`"
                class="w-full"
                alt="contact"
                loading="lazy"
            />
        </a>
        <div
            class="block cursor-pointer hover:ring-opacity-90"
            @click="loadLiveChat"
        >
            <img
                :src="`${staticUrl}/assets/img/hot-line/chat.svg`"
                class="w-full"
                alt="contact"
                loading="lazy"
            />
        </div>
    </div>
</template>

<script setup>
const staticUrl = useRuntimeConfig().public.staticUrl
const lineContact = useRuntimeConfig().public.LINE_CONTACT
function loadLiveChat() {
    if (typeof window !== 'undefined') {
        window.LiveChatWidget.call('maximize')
    }
}
</script>
<style scoped></style>
