import * as Yup from 'yup'
export const registerSchema = Yup.object().shape({
    username: Yup.string()
        .required('error.required')
        .min(6, 'error.min_6_character')
        .max(30, 'error.max_30_character')
        .matches(/^[\u0E00-\u0E7Fa-zA-Z0-9_]+$/, 'error.alphanumeric_only'),
    password: Yup.string()
        .required('error.pass_required')
        .min(6, 'error.pass_min_6_character')
        .max(32, 'error.pass_max_32_character')
        .matches(/^\S+$/, 'error.no_spaces_allowed'),
    // fullname: Yup.string()
    //     .required('error.required')
    //     .min(6, 'error.min_6_character')
    //     .max(30, 'error.max_30_character')
    //     .matches(
    //         /^[\u0E00-\u0E7Fa-zA-Z0-9_]+$/,
    //         'error.only_digits_and_lettters'
    //     )
    //     .test(
    //         'fullname-not-match',
    //         'error.fullname_must_not_match',
    //         function (value) {
    //             if (this.parent.username === value) {
    //                 return false
    //             } else {
    //                 return true
    //             }
    //         }
    //     ),
    referral_code: Yup.string(),
    // confirmPassword: Yup.string()
    //     .required('error.required')
    //     .oneOf([Yup.ref('password'), null], 'error.password_must_matche'),
    phone: Yup.string()
        .required('error.required')
        .test('phonenumber-not-match', function (value) {
            if (/[^0-9]/.test(value)) {
                return this.createError({
                    message: 'error.phone_only_digits',
                })
            } else if (/^[1-9][0-9]*$/.test(value)) {
                return this.createError({
                    message: 'error.start_with_zero',
                })
            } else if (value.length < 10) {
                return this.createError({
                    message: 'error.min_10_digits',
                })
            } else if (value.length > 12) {
                return this.createError({
                    message: 'error.max_12_digits',
                })
            } else {
                return true
            }
        }),
})
