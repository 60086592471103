<template>
    <CommonModal
        :show="showCashbackRebateModal"
        size="md"
        sticky=""
        @close="closeSelf"
    >
        <template #header>
            <div class="relative overflow-hidden rounded-lg bg-white">
                <button
                    type="button"
                    class="absolute right-2.5 top-2.5 inline-flex h-8 w-8 items-center justify-center rounded-full text-sm"
                    @click="closeSelf"
                >
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
        </template>
        <template #default>
            <div
                class="flex flex-col items-center justify-center bg-white px-6 py-12"
            >
                <!-- Icon -->
                <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_801_6622)">
                        <path
                            d="M41.0212 9.60218C40.5203 7.56419 39.0247 5.97279 37.0211 5.3473C35.0075 4.71761 32.8793 5.17699 31.3107 6.5681L28.8164 8.77279L40.7702 13.1235C41.192 12.0151 41.3154 10.7997 41.0212 9.60218Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M27.2492 5.09039C26.9373 3.0152 25.5947 1.27399 23.6584 0.465296C21.7221 -0.344763 19.5538 -0.0857786 17.8564 1.15367C16.8619 1.88102 16.1759 2.9108 15.7871 4.03112L27.7424 8.38258L27.2492 5.09039Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M21.0352 24.6562V50.0014H23.9662V25.723L21.0352 24.6562Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M4.91475 23.7188C4.10469 23.7188 3.44922 24.3742 3.44922 25.1843V45.6038C3.44922 48.0281 5.42139 50.0004 7.8458 50.0004H18.1044V23.7188H4.91475Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M26.8984 26.7891V50.0005H37.157C39.5813 50.0005 41.5536 48.0283 41.5536 45.6039V32.123L26.8984 26.7891Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M26.0386 10.8822L22.0293 21.8984L24.7845 22.9012L28.7938 11.885L26.0386 10.8822Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M43.9427 17.3976L31.5488 12.8867L27.5391 23.903L42.6876 29.4166C42.8522 29.4767 43.0226 29.5053 43.1885 29.5053C43.7867 29.5053 44.3491 29.136 44.5652 28.5406L46.5702 23.032C47.4042 20.7439 46.2215 18.228 43.9427 17.3976Z"
                            fill="#F7D936"
                        />
                        <path
                            d="M10.8929 5.36975C8.61878 4.53801 6.0929 5.70373 5.25687 7.99738L3.25325 13.506C2.97562 14.2659 3.3678 15.1075 4.12913 15.3837L19.2772 20.8971L23.2871 9.88078L10.8929 5.36975Z"
                            fill="#F7D936"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_801_6622">
                            <rect width="50" height="50" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <div class="mb-8 mt-4 text-center font-semibold">
                    {{
                        $t(
                            `user.promotion.rebate_modal_template.${data?.method}`
                        )
                    }}
                </div>
                <div
                    v-if="data.method === 'cashback_livecasino'"
                    class="grid w-full space-y-2 border border-grey-200 px-4 py-2 text-sm"
                >
                    <div class="flex justify-between">
                        <div class="font-semibold">
                            {{
                                $t(
                                    'user.promotion.rebate_modal_template.rebate_bonus'
                                )
                            }}
                        </div>
                        <div>
                            {{ data?.bonus }} {{ $t('common.money_symbol') }}
                        </div>
                    </div>
                    <div class="flex justify-between">
                        <div class="font-semibold">
                            {{
                                $t(
                                    'user.promotion.rebate_modal_template.additional_bonus'
                                )
                            }}
                        </div>
                        <div>
                            {{ data?.additional_bonus }}
                            {{ $t('common.money_symbol') }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        data.method === 'weekly_cashback_sport' ||
                        data.method === 'weekly_cashback_slot'
                    "
                    class="grid w-full space-y-2 border border-grey-200 px-4 py-2 text-sm"
                >
                    <div class="flex justify-between">
                        <div class="font-semibold">
                            {{
                                $t(
                                    'user.promotion.rebate_modal_template.additional_bonus'
                                )
                            }}
                        </div>
                        <div>
                            {{ data?.amount }} {{ $t('common.money_symbol') }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        data.method === 'daily_cashback_sport' ||
                        data.method === 'daily_cashback_slot'
                    "
                    class="grid w-full space-y-2 border border-grey-200 px-4 py-2 text-sm"
                >
                    <div class="flex justify-between">
                        <div class="font-semibold">
                            {{
                                $t(
                                    'user.promotion.rebate_modal_template.rebate_bonus'
                                )
                            }}
                        </div>
                        <div>
                            {{ data?.amount }} {{ $t('common.money_symbol') }}
                        </div>
                    </div>
                </div>
                <button
                    class="mt-6 w-full rounded-sm bg-bigg-yellow p-3"
                    @click="closeSelf"
                >
                    {{ $t('common.receive') }}
                </button>
            </div>
        </template>
        <template #footer> </template>
    </CommonModal>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
// eslint-disable-next-line vue/require-prop-types
const { data } = defineProps(['data'])
// [
//     {
//         id: 87,
//         user_id: 436717,
//         username: 'live002',
//         is_show: true,
//         created_time: '2024-07-16T11:03:25.176Z',
//         method: 'cashback_livecasino',
//         amount: 0.315,
//         note: 'event cashback addition bonus livecasino stake 105 | percent 0.3 | amount 0.315 | | percent additional bonus 0 | amount additional bonus 0 | date 2024-07-16 ',
//         ranking: '',
//         vip: '',
//         vip_time: '',
//         vip_info: '',
//         title: 'Weekly Exclusive Rebate Bonus for Live Casinos',
//     }
// ]
const emit = defineEmits(['ModalClosed'])

const useModalStoreInstance = useModalStore()
const { showCashbackRebateModal } = storeToRefs(useModalStoreInstance)

const { execute } = useLazyFetch(() => `/popup/close/${data.id}`, {
    method: 'post',
    baseURL: '/api-promotion/v1',
    immediate: false,
    cache: false,
    initialCache: false,
})

const closeSelf = async () => {
    await execute()
    emit('ModalClosed', true)
    showCashbackRebateModal.value = false
}
</script>
