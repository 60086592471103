<template>
    <CommonModal
        :show="showForgotPasswordModal"
        :sticky="true"
        :size="step === 5 ? 'sm' : 'md'"
        @close="closeSelf"
    >
        <template #header>
            <div
                v-if="steps[step]?.title"
                class="relative bg-white pt-12 text-center"
            >
                <h3 class="text-[24px] font-bold uppercase text-black">
                    {{ $t(steps[step]?.title) }}
                </h3>
            </div>
        </template>
        <template #default>
            <div class="bg-white px-[24px] pb-[48px] pt-4">
                <component
                    :is="steps[step]?.component"
                    @next="goToNext"
                ></component>
            </div>
        </template>
        <template #footer> </template>
    </CommonModal>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
import { nextTick } from 'vue'

const useModalStoreInstance = useModalStore()
const { showForgotPasswordModal } = storeToRefs(useModalStoreInstance)

const step = ref(0)

const steps = shallowRef([
    {
        id: 1,
        title: 'modal.forgot_password.forgot_password',
        component: resolveComponent('ModalForgotPasswordStepInit'),
    },
    {
        id: 2,
        title: 'modal.forgot_password.change_password',
        component: resolveComponent('ModalForgotPasswordSendOTP'),
    },
    // {
    //     id: 2,
    //     title: 'modal.forgot_password.forgot_password',
    //     component: resolveComponent('ModalForgotPasswordStep1'),
    // },
    // {
    //     id: 2,
    //     title: 'OTP Verification',
    //     component: resolveComponent('ModalForgotPasswordStep2'),
    // },a
    // {
    //     id: 3,
    //     title: 'Create new password',
    //     component: resolveComponent('ModalForgotPasswordStep3'),
    // },
    // {
    //     id: 4,
    //     title: 'New password created',
    //     component: resolveComponent('ModalForgotPasswordStep4'),
    // },
    {
        id: 5,
        title: null,
        component: resolveComponent('ModalForgotPasswordPassCreated'),
    },
])

const closeSelf = () => {
    step.value = 0
    showForgotPasswordModal.value = false
}
const goToNext = () => {
    if (step.value >= steps.value.length - 1) {
        step.value = 0
        closeSelf()
        return
    } else {
        step.value = step.value + 1
    }
}

onMounted(async () => {
    await nextTick(() => {
        step.value = 0
        // activeStep.value = steps.value[0]
    })
})
</script>
