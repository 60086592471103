<template>
    <div class="container mx-auto my-0 py-4">
        <nav class="flex items-center justify-between space-x-3 lg:h-auto">
            <NuxtLink to="/" class="flex flex-shrink-0 items-center">
                <img
                    :src="`/assets/logo.svg`"
                    class="mb-2 h-7 lg:h-10"
                    alt="big Logo"
                />
            </NuxtLink>
            <div>
                <client-only>
                    <div class="flex items-center">
                        <template v-if="isLoggedIn">
                            <div class="flex">
                                <!--  bg-gradient-to-r from-transparent to-grey-100 -->
                                <NuxtLink to="/account/deposit">
                                    <div
                                        class="hidden h-10 items-center justify-center rounded-full bg-gradient-to-r from-black to-[#2A2D31] pl-5 lg:flex"
                                    >
                                        <div class="text-right">
                                            <p
                                                class="max-w-[150px] truncate text-xs text-grey-200"
                                            >
                                                {{ user?.fullname }}
                                            </p>
                                            <span
                                                class="text-sm font-semibold text-white"
                                            >
                                                {{ $t('common.money_symbol') }}
                                                {{ formatCurrency(balance) }}
                                            </span>
                                        </div>
                                        <div
                                            class="ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-bigg-yellow p-2"
                                        >
                                            <img
                                                :src="`${staticUrl}/assets/img/icons/profile.svg`"
                                                alt="profile"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="flex max-h-[34px] rounded-full bg-[#2A2D31] pl-[15px] lg:hidden"
                                    >
                                        <div
                                            class="flex flex-col justify-center py-[3px] text-left"
                                        >
                                            <p
                                                class="max-w-[150px] truncate text-[11px] leading-none text-[#A9A9A9]"
                                            >
                                                {{ $t('common.money_symbol') }}
                                            </p>
                                            <span
                                                class="text-[13px] font-semibold leading-none text-white"
                                            >
                                                {{ formatCurrency(balance) }}
                                            </span>
                                        </div>
                                        <div
                                            class="ml-5 flex h-[34px] w-[34px] items-center justify-center rounded-full bg-bigg-yellow"
                                        >
                                            <img
                                                class="w-8"
                                                :src="`${staticUrl}/assets/img/icons/add.svg`"
                                                alt="add"
                                            />
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                        </template>
                        <template v-else>
                            <button
                                class="h-[36px] w-[82px] rounded-lg border-2 border-bigg-yellow bg-bigg-yellow text-xs font-medium capitalize text-black lg:h-[40px] lg:w-[98px] lg:text-sm"
                                @click="onSingUpClick"
                            >
                                {{ $t('home.join_now') }}
                            </button>
                            <button
                                class="ml-[5px] h-[36px] w-[82px] rounded-lg border-2 border-bigg-yellow text-xs font-medium capitalize text-white xs:ml-[12px] lg:h-[40px] lg:w-[98px] lg:text-sm"
                                @click="onSingInClick"
                            >
                                {{ $t('modal.login.login_btn') }}
                            </button>
                        </template>
                        <div class="ml-4 hidden lg:flex">
                            <ThaBlocksLanguageSwitcher />
                        </div>
                    </div>
                </client-only>
            </div>
        </nav>
    </div>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore, useUserStore } from '~/stores'
import { formatCurrency } from '~/utils/transformText'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
// Store
const useModalStoreInstance = useModalStore()
const { showLoginModal, showSignUpModal } = storeToRefs(useModalStoreInstance)
const useUserStoreInstance = useUserStore()
const { isLoggedIn, balance, refreshId, navigateAfterLogin, user } =
    storeToRefs(useUserStoreInstance)
// Global hook
const route = useRoute()
const staticUrl = useRuntimeConfig().public.staticUrl

// Local state
const currentTime = ref('')
const timer = ref(null)
const timeZone = ref('')
// Methods

const onSingInClick = () => {
    showLoginModal.value = true
}
const onSingUpClick = () => {
    showSignUpModal.value = true
}

// Watchers
onMounted(() => {
    timer.value = setInterval(() => {
        currentTime.value = `${dayjs().format('DD/MM/YYYY HH:mm:ss')}`
    }, 1000)

    const match = dayjs()
        .format('ZZ')
        .match(/([+-]\d+)/)
    if (match && match[1]) {
        // Extracted the numbers after '+' or '-'
        const number = parseInt(match[1], 10) // Parse the number
        let result = match[1][0] + Math.abs(number) // Construct the result string
        // Check if the last two characters are '00' and remove them
        if (result.slice(-2) === '00') {
            result = result.slice(0, -2)
        }

        timeZone.value = result
    }
    if (isLoggedIn.value && refreshId.value) {
        // console.log('Start Refresh after page reload')
        useUserStoreInstance.clearRefresh()
        useUserStoreInstance.startRefresh()
    }
})
onBeforeMount(() => {
    if (timer.value) {
        clearInterval(timer.value)
    }
})
watch(
    () => isLoggedIn.value,

    (newVal, _) => {
        if (newVal) {
            showLoginModal.value = false
            showSignUpModal.value = false
            if (navigateAfterLogin.value) {
                navigateTo(navigateAfterLogin.value)
            }
            if (route.query.login) {
                reloadNuxtApp()
            }
        } else {
            navigateTo('/')
        }
    }
)
</script>
<style lang="scss" scoped>
#username {
    :deep() {
        #usernametooltip {
            right: 0;
            left: auto;
            white-space: nowrap;
        }
        .tooltip-arrow {
            right: 12px;
        }
    }
}
</style>
