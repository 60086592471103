<template>
    <div class="flex flex-col justify-center space-y-4">
        <button
            type="submit"
            class="relative mx-[auto] mt-[5px] w-[236px] rounded-[4px] bg-[#00bd12] px-5 py-[12px] text-sm font-medium text-white"
        >
            <img
                :src="`${staticUrl}/assets/img/footer/line.svg`"
                alt="line"
                class="absolute left-[16px] top-[50%] translate-y-[-50%]"
            />
            Verify Line Account
        </button>
        <div
            class="mx-auto max-w-[270px] space-x-1 text-center text-[13px] font-normal"
        >
            <span class="text-[#6E6F74]">
                {{ $t('modal.forgot_password.support_text') }}
            </span>
            <div class="cursor-pointer" @click="loadLiveChat">
                <span class="mr-1 text-[#6E6F74]">
                    {{ $t('common.contact') }}
                </span>

                <span class="text-bigg-red underline">{{
                    $t('modal.forgot_password.support_247')
                }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
function loadLiveChat() {
    if (typeof window !== 'undefined') {
        window.LiveChatWidget.call('maximize')
    }
}

const staticUrl = useRuntimeConfig().public.staticUrl
</script>
