<template>
    <div
        class="group text-[11px] font-light"
        exact-active-class="text-bigg"
        @click="item.action"
    >
        <div
            class="nav-item active relative flex flex-col items-center justify-between"
        >
            <img
                :class="{
                    'active-footer-svg': isActive,
                }"
                :src="`${staticUrl}/assets/icons/navfooter/${item.key}_inactive.svg`"
                :alt="`icon for ${item.key}`"
            />

            <div
                class="break-words text-center font-medium text-[#6E6F74] group-[.router-link-exact-active]:text-black"
                :class="{
                    'text-black': isActive,
                }"
            >
                {{ $t(item.i18_key) }}
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        default: () => ({
            name: 'deposit',
            i18_key: 'deposit',
            id: 'deposit',
            url: '/account/deposit',
            icon: 'info',
        }),
        required: true,
    },
})
const route = useRoute()
const staticUrl = useRuntimeConfig().public.staticUrl
const isActive = computed(() => {
    const fullPath = route.fullPath.toString()
    const userRouter = [
        '/setting',
        '/account',
        '/other/vip',
        '/account/bet-history',
        '/account/transaction-history',
        '/account/profile',
        '/account/bank-account',
        '/account/change-password',
        '/account/vip',
        '/account/general-promotions',
        '/account/referral',
    ]
    const fundsRouter = ['/account/deposit', '/account/withdraw']
    return (
        fullPath === props.item.url ||
        (props.item.id === 'setting' &&
            userRouter.some((r) => r === fullPath)) ||
        (props.item.id === 'promotion' && fullPath.includes('promotion')) ||
        (props.item.id === 'deposit' &&
            fundsRouter.some((r) => fullPath.includes(r)))
    )
})
</script>

<style lang="scss" scoped>
.active-footer-svg {
    filter: brightness(0) saturate(100%) invert(79%) sepia(39%) saturate(632%)
        hue-rotate(355deg) brightness(101%) contrast(101%);
}
.tooltip-nav-footer__list {
    .tooltip-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 8px;
        img {
            width: 48px;
            height: 48px;
            object-fit: contain;
        }
        span {
            font-weight: 500;
            font-size: 10px;
            line-height: calc(13 / 10);
            color: #000;
        }
    }
    @media (max-width: 414px) {
        width: 195px;
        height: 130px;
        padding: 16px 24px 32px 16px;
    }
}
</style>
