<script setup>
import { useField } from 'vee-validate'
const props = defineProps({
    name: String,
    modelValue: String,
    classCustom: String,
})
const { value, errorMessage } = useField(() => props.name, undefined, {
    syncVModel: true,
})
</script>
<template>
    <input
        v-model="value"
        class="w-full rounded-[4px] border-[1px] border-[#E4E4E6] text-[#676767] focus:border-[#F7D936] focus:ring-[#F7D936]"
        :class="[
            !!errorMessage
                ? 'border-[#ff1616c5] text-[#ff1616c5] focus:border-[#ff1616c5] focus:ring-[#ff1616c5]'
                : 'focus:border-[#F7D936] focus:ring-[#F7D936]',
            classCustom,
        ]"
        v-bind="$attrs"
    />
    <div v-if="errorMessage" class="mt-1 text-[12px] text-[#ff1616c5]">
        {{ $t(errorMessage) }}
    </div>
</template>

<style scoped lang="scss">
.base-input {
    position: relative;
    input {
        font-size: 14px;
        height: 45px;
        padding: 0 13px;
        &::placeholder {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: #b4b4b4;
        }
        &:focus {
            outline: none !important;
        }
    }
    &__form-error {
        input {
            border: 1px solid #ff1616;
        }
    }
}
</style>
