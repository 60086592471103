<template>
    <CommonModalAlert
        :show="showKycVerificationPending"
        size="sm"
        sticky
        :class-custom="'!max-w-[390]'"
        @close="closeSelf"
    >
        <template #header>
            <div
                class="absolute right-3 top-3 h-[30px] w-[30px] cursor-pointer rounded-full bg-[#F2F4F8] p-[10px]"
                @click="closeSelf"
            >
                <img :src="`${staticUrl}/assets/icons/close.svg`" alt="close" />
            </div>
        </template>
        <template #default>
            <div
                class="flex flex-col items-center justify-center space-y-5 rounded-[12px] bg-[#FAFAFA] p-[24px] py-12 font-roboto text-sm font-medium"
            >
                <div class="min-h-[96px]">
                    <img
                        :src="`${staticUrl}/assets/img/kyc/verify.svg`"
                        alt="verify"
                    />
                </div>
                <div
                    class="text-[22px] font-[600] leading-[28px] text-[#1A181B]"
                >
                    {{ $t('modal.kyc_verification_pending.title') }}
                </div>

                <div class="text-[16px] leading-[24px] text-[#59565A]">
                    {{ $t('modal.kyc_verification_pending.content') }}
                </div>

                <div class="flex w-full gap-[10px]">
                    <button
                        class="h-[44px] w-full rounded-md bg-[#F7D936] text-[14px]"
                        @click="closeSelf"
                    >
                        {{ $t('common.ok') }}
                    </button>
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModalAlert>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
const useModalStoreInstance = useModalStore()

const { showKycVerificationPending } = storeToRefs(useModalStoreInstance)
const staticUrl = useRuntimeConfig().public.staticUrl
const closeSelf = () => {
    showKycVerificationPending.value = false
}
</script>
