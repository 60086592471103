<template>
    <Transition>
        <div
            v-if="isShowModal"
            class="absolute inset-0 z-[99] flex items-center justify-center bg-[#000000a6]"
        >
            <div
                ref="el"
                v-on-click-outside="onClickOutsideHandler"
                class="fixed inset-0 z-40 overflow-y-scroll bg-white px-[20px] pb-[100px] lg:relative lg:mx-auto lg:h-[700px] lg:min-w-[1124px] lg:overflow-y-scroll lg:px-[0] lg:pb-[200px]"
            >
                <div class="z-[99] lg:fixed lg:min-w-[1105px] lg:bg-white">
                    <ModalKycCloseBtn />
                    <ModalKycTabs />
                </div>
                <div class="lg:pt-[120px]">
                    <ModalKycForm />
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { useScroll } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'
const { closeModal, isShowModal, stepActive } = useSharedKyc()
const el = ref(null)
const ignoreElRef = ref(null)
onMounted(() => {
    ignoreElRef.value = document.getElementById('toast')
})

const onClickOutsideHandler = [
    () => {
        closeModal()
    },
    { ignore: [ignoreElRef] },
]
const { y } = useScroll(el)

watch(
    () => stepActive.value,
    // eslint-disable-next-line
    (newVal, _) => {
        y.value = 0
    }
)
</script>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
