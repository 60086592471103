<template>
    <CommonModalAlert
        :show="showGameDenyModal"
        :class-custom="'w-[98.6%] sm:w-[390px] md:w-[390px] lg:w-[390px]'"
        size="sm"
        sticky
        @close="closeSelf"
    >
        <template #default>
            <div
                class="relative overflow-hidden rounded-lg bg-white px-[25px] py-[48px]"
            >
                <button
                    type="button"
                    class="absolute right-2.5 top-2.5 inline-flex h-8 w-8 items-center justify-center rounded-full text-sm"
                    @click="closeSelf"
                >
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="15" cy="15" r="15" fill="#F2F4F8" />
                        <path
                            d="M10 10L20 20"
                            stroke="#6E6F74"
                            stroke-width="2"
                        />
                        <path
                            d="M20 10L10 20"
                            stroke="#6E6F74"
                            stroke-width="2"
                        />
                    </svg>

                    <span class="sr-only">Close modal</span>
                </button>
                <div
                    class="flex flex-col items-center justify-center space-y-8 bg-white"
                >
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_4255_41362)">
                            <path
                                d="M23.2338 0.932594L0.0988272 41.0036C-0.670173 42.3356 0.290827 43.9996 1.82783 43.9996H48.0988C49.6358 43.9996 50.5968 42.3356 49.8278 41.0036L26.6928 0.932594C25.9238 -0.399406 24.0028 -0.399406 23.2338 0.932594Z"
                                fill="#F7D936"
                            />
                            <path
                                d="M25.0008 37.9996C26.1054 37.9996 27.0008 37.1042 27.0008 35.9996C27.0008 34.895 26.1054 33.9996 25.0008 33.9996C23.8963 33.9996 23.0008 34.895 23.0008 35.9996C23.0008 37.1042 23.8963 37.9996 25.0008 37.9996Z"
                                fill="white"
                            />
                            <path
                                d="M23.7928 30.9996L22.2928 17.9996C22.1198 16.5036 23.1928 15.1516 24.6888 14.9786C26.1848 14.8056 27.5368 15.8786 27.7098 17.3746C27.7338 17.5816 27.7318 17.8006 27.7098 17.9996L26.2098 30.9996C26.1328 31.6666 25.5298 32.1456 24.8618 32.0686C24.2898 32.0026 23.8558 31.5486 23.7928 30.9996Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_4255_41362">
                                <rect width="50" height="50" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div class="text-sm font-medium leading-[1.285]">
                        {{ $t('modal.game_deny.reason') }}
                    </div>
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModalAlert>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
const useModalStoreInstance = useModalStore()
const { showGameDenyModal } = storeToRefs(useModalStoreInstance)

const closeSelf = () => {
    showGameDenyModal.value = false
}
</script>
