<template>
    <div class="w-full">
        <div
            class="container grid grid-cols-10 gap-4 border-b border-grey-50 pb-12 lg:pt-20"
        >
            <div class="col-span-3 flex flex-col">
                <img
                    :src="`/assets/logo.svg`"
                    class="w-auto lg:w-2/3"
                    alt="big Logo"
                />
                <div class="pt-6 text-xs font-normal leading-tight text-white">
                    {{ $t('footer.site_info') }}
                </div>
                <div class="flex w-2/3 items-center space-x-4 pt-6">
                    <div>
                        <img
                            :src="`${
                                useRuntimeConfig().public.staticUrl
                            }/assets/img/footer/provider-1.png`"
                            alt="provider"
                        />
                    </div>
                    <div>
                        <img
                            :src="`${
                                useRuntimeConfig().public.staticUrl
                            }/assets/img/footer/provider-2.png`"
                            alt="provider"
                            class="w-fit"
                        />
                    </div>
                </div>
            </div>
            <div class="col-span-2 flex flex-col pt-2">
                <div class="pb-4 text-sm font-medium text-[#757575]">
                    {{ $t('footer.games') }}
                </div>
                <ul
                    class="columns-2 space-y-2 text-sm font-normal leading-tight text-white lg:space-y-4"
                >
                    <li v-for="game in gameList" :key="game.url">
                        <template v-if="game.url">
                            <nuxt-link :to="game.url">
                                {{ $t(game.i18_key) }}
                            </nuxt-link>
                        </template>
                        <template v-else>
                            <div
                                class="cursor-pointer"
                                @click="handleRedirect(game)"
                            >
                                {{ $t(game.i18_key) }}
                            </div>
                        </template>
                    </li>
                </ul>
            </div>
            <div class="col-span-1 flex flex-col">
                <div class="pb-4 text-sm font-medium text-[#757575]">
                    {{ $t('footer.info') }}
                </div>
                <ul
                    class="space-y-2 text-sm font-normal leading-tight text-white lg:space-y-4"
                >
                    <li v-for="link in quick_links" :key="link.i18_key">
                        <nuxt-link :to="link.url">
                            {{ $t(link.i18_key) }}
                        </nuxt-link>
                    </li>
                    <li v-if="DISPLAY_NEWS === '1'">
                        <nuxt-link :to="'/news'">
                            {{ $t('news.news') }}
                        </nuxt-link>
                    </li>
                </ul>
            </div>
            <div class="col-span-2 flex flex-col">
                <div class="pb-4 text-sm font-medium text-[#757575]">
                    {{ $t('footer.payment_info') }}
                </div>
                <ul class="flex flex-wrap gap-3">
                    <li v-for="payment in paymentList" :key="payment">
                        <img
                            :src="`/assets/img/footer/payment/${payment}.svg`"
                            :alt="payment"
                            class="h-[36px]"
                        />
                    </li>
                </ul>
            </div>
            <div class="col-span-2 flex w-full flex-col space-y-4">
                <div class="text-sm font-medium text-[#757575]">
                    {{ $t('supports.supports') }}
                </div>

                <BlocksLiveChat />
                <BlocksLineChat />
                <BlocksContactPhone />
            </div>
        </div>
        <div
            class="container flex items-start justify-between pb-20 pt-10 text-xs text-white"
        >
            <div>
                {{ $t('footer.copy_rights') }}
                © {{ new Date().getFullYear() }}
            </div>

            <div class="flex gap-4">
                <NuxtLink to="/help?tab=terms">
                    {{ $t('quick_links.terms_and_conditions') }}
                </NuxtLink>
                <NuxtLink to="/help?tab=policy">
                    {{ $t('quick_links.privacy_policy') }}
                </NuxtLink>
            </div>
        </div>
    </div>
</template>
<script setup>
// Todo: Will move to constant
import { useAlertStore, useGamesStore } from '~/stores'

const gameList = ref([
    {
        id: 'Sports',
        url: '/sports',
        title: 'Sports',
        i18_key: 'header.nav_menu.sports',
    },
    {
        id: 'live-casino',
        url: '/casino',
        title: 'Live Casino',
        i18_key: 'header.nav_menu.live-casino',
    },
    {
        id: 'fishing',
        url: '/games/fishing',
        title: 'Fishing',
        i18_key: 'header.nav_menu.fishing',
    },
    {
        id: 'cock_fight',
        title: 'Cockfight',
        i18_key: 'header.nav_menu.cockfight',
    },
    {
        id: 'table_game',
        url: '/games/tables?partner=',
        title: 'Table Games',
        i18_key: 'header.nav_menu.tables',
    },
    {
        id: 'slots',
        url: '/games/slots?partner=',
        title: 'Slots',
        i18_key: 'header.nav_menu.slot',
    },
    {
        id: 'lotto',
        url: '/games/lottery?partner=',
        title: 'Lotto',
        i18_key: 'header.nav_menu.lottery',
    },
    {
        id: 'quick_game',
        url: '/games/instants?partner=',
        title: 'Quick Games',
        i18_key: 'header.nav_menu.quick_game',
    },
    {
        id: 'esports',
        title: 'E-Sports',
        i18_key: 'header.nav_menu.e-sports',
        url: '/e-sports',
    },
])

const quick_links = ref([
    {
        id: 'about_us',
        url: '/help?tab=about-us',
        title: 'about_us',
        i18_key: 'quick_links.about_us',
    },
    {
        id: 'promotions',
        url: '/promotions',
        title: 'promotions',
        i18_key: 'quick_links.promotions',
    },
    {
        name: 'deposit',
        i18_key: 'quick_links.deposit',
        key: 'deposit',
        id: 'deposit',
        url: '/account/deposit',
    },
    {
        name: 'withdraw',
        i18_key: 'quick_links.withdraw',
        key: 'withdraw',
        id: 'withdraw',
        url: '/account/withdraw',
    },
    // {
    //     id: 'terms_and_conditions',
    //     url: '/help?tab=terms',
    //     title: 'terms_and_conditions',
    //     i18_key: 'quick_links.terms_and_conditions',
    // },
    {
        id: 'privacy_policy',
        url: '/help?tab=policy',
        title: 'privacy_policy',
        i18_key: 'quick_links.privacy_policy',
    },
])

const paymentList = ['easypay', 'bigpayz', 'aeepay', 'usdt', 'bank']
// const lineContact = useRuntimeConfig().public.LINE_CONTACT
// const socialList = [
//     {
//         url: lineContact,
//         name: 'line',
//     },
// ]

const handleRedirect = (item) => {
    if (item.id === 'cock_fight') {
        const toSend = {
            partnerProvider: 'cockfight',
            partnerGameId: 'lobby',
        }
        handleGetGameUrl(toSend)
        return
    }
    if (item.id === 'esports') {
        const toSend = {
            partnerProvider: 'bti',
        }
        handleGetGameUrl(toSend)
        return
    }
}
const alertStore = useAlertStore()
const gameStoreInstance = useGamesStore()
const { getGameUrl } = gameStoreInstance
const DISPLAY_NEWS = useRuntimeConfig().public.DISPLAY_NEWS
const handleGetGameUrl = async (toSend) => {
    try {
        const { data } = await getGameUrl(toSend)
        if (data.value?.status === 'OK') {
            const { url } = data.value.data
            if (import.meta.client) {
                setTimeout(() => window.open(url, '_blank'))
            }
        }
    } catch (error) {
        alertStore.showMessageError(error)
    }
}
</script>
