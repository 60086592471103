<template>
    <CommonModal :show="showPromoBtiModal" size="md" hide-close>
        <template #header>
            <div class="relative text-center">
                <div
                    class="absolute right-3 top-3 h-[30px] w-[30px] cursor-pointer rounded-full bg-[#F2F4F8] p-[10px]"
                    @click="closeSelf"
                >
                    <img
                        :src="`${staticUrl}/assets/icons/close.svg`"
                        alt="close"
                    />
                </div>
            </div>
        </template>
        <template #default>
            <img
                :src="`${staticUrl}/assets/img/banner/euro/banner-7-pop-up-${locale}.jpg`"
                class="w-full"
                alt=""
            />
        </template>
        <template #footer> </template>
    </CommonModal>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
const { locale } = useI18n({})

const useModalStoreInstance = useModalStore()
const { showPromoBtiModal } = storeToRefs(useModalStoreInstance)
const staticUrl = useRuntimeConfig().public.staticUrl
const closeSelf = () => {
    showPromoBtiModal.value = false
}
</script>
<style lang="scss" scoped>
@media (max-width: 1023px) {
    .group-input {
        background: #f6f7fa;
        > div > div {
            display: grid;
            grid-template-columns: 1fr 2.2fr 1fr;
            align-items: center;
            label {
                position: relative;
                order: 1;
                transform: unset;
                padding-left: 5px;
                top: 0;
                &:before {
                    content: unset;
                }
            }
            input {
                order: 2;
                background: none;
                border: none;
                text-align: right;
                padding: 0;
            }
        }
    }
}
:deep() {
    .m-input-wrapper-input {
        [type='text']:focus {
            --tw-ring-color: #fff;
        }
    }
    .m-country-selector {
        .m-select-list {
            min-width: 250px;
            max-width: 250px;
        }
    }
    .maz-rounded {
        border-radius: 6px;
    }
    .m-phone-number-input.--responsive {
        .m-country-selector__select {
            max-width: 86px;
        }
    }
    .m-country-selector__country-flag {
        &.--should-have-bottom-flag {
            bottom: 6px;
        }
    }
    .m-input {
        &.--should-up {
            &.--has-label {
                &.m-select-input {
                    .m-input-label {
                        display: none;
                    }
                }
            }
        }
    }
    .m-input.--has-label .m-input-label {
        font-size: 0.875rem;
    }
    .maz-border-primary {
        border-color: rgb(247 217 54 / 1);
    }
    .m-phone-number-input.--responsive {
        flex-direction: row;
        width: 100%;
    }
    .m-input.--md {
        height: 42px;
    }
    .m-phone-number-input.--responsive {
        .m-country-selector {
            max-width: 86px;
            min-width: 86px;
        }
        .m-phone-input {
            margin-top: 0;
            flex: 1;
        }
    }
    .m-input-wrapper-right {
        padding-right: 0;
    }
    .m-phone-number-input.--responsive {
        .m-phone-input.--border-radius {
            .m-input-wrapper {
                border-radius: 0 6px 6px 0;
            }
        }
    }
    .m-phone-number-input.--responsive {
        .m-country-selector__select {
            .m-select-input {
                .m-input-wrapper {
                    border-radius: 6px 0 0 6px;
                }
            }
            .m-input-wrapper.--default-border.maz-rounded {
                border-right-width: 0;
            }
        }
    }
    @media (min-width: 425px) {
        .m-phone-number-input.--responsive {
            width: 100%;
        }
        .m-input.--block {
            height: 42px;
        }
        .m-phone-number-input.--responsive {
            .m-country-selector__select {
                .m-select-input {
                    .m-input-wrapper {
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 6px;
                        height: 42px;
                    }
                }
            }
        }
        .m-phone-number-input.--responsive .m-phone-input {
            height: 42px;
            &.--border-radius {
                .m-input-wrapper {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }

    .m-country-selector:not(.--no-flags) {
        .m-country-selector__select {
            .m-select-input input {
                padding: 0.625rem 0;
            }
        }
    }
    .m-select-chevron {
        display: none;
    }
    .m-input.--has-label {
        .m-input-input {
            padding: 1rem 0.75rem 0.25rem 0.75rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: #000;
        }
    }
    @media (max-width: 375px) {
        .m-phone-number-input.--responsive .m-country-selector {
            max-width: 72px;
            min-width: 72px;
        }
        .m-phone-number-input.--responsive .m-country-selector__select {
            max-width: 72px;
            .m-select-input {
                input {
                    padding-left: 2rem !important;
                }
            }
        }
    }
}
</style>
