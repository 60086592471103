<template>
    <div class="relative">
        <label
            v-if="label"
            class="mb-2 block text-sm font-medium text-grey-600"
        >
            {{ label }}
            <span
                v-if="isRequired"
                title="required"
                class="text-sm font-normal text-[#FF5959]"
            >
                *
            </span>
        </label>
        <div class="relative">
            <input
                :id="name + '_id'"
                class="block w-full appearance-none rounded p-2.5 text-sm focus:ring-0"
                :class="[
                    classes,
                    disabled ? 'bg-bigg-gray' : '',
                    errorMessage || error
                        ? 'border-error focus:border-error'
                        : 'border-[#ECECEC] focus:border-bigg-yellow',
                ]"
                autocomplete="off"
                :name="name"
                :type="type"
                :value="inputValue"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                @input="handleChange"
                @blur="handleBlur"
            />
            <slot name="func" :data="inputValue"></slot>
        </div>
        <div v-if="errorMessage" class="mt-[4px] text-[12px] text-[#F54C4C]">
            {{ $t(errorMessage) }}
        </div>
        <div v-else-if="error" class="mt-[4px] text-[12px] text-[#F54C4C]">
            {{ error }}
        </div>
    </div>
</template>
<script setup>
import { toRef } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    enableTooltip: {
        type: Boolean,
        default: true,
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    classes: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: null,
    },
})
const name = toRef(props, 'name')
const theValue = toRef(props, 'value')

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    // meta,
} = useField(name, undefined, {
    initialValue: props.value,
})

watch(
    () => theValue.value,

    (newVal, _) => {
        if (newVal) {
            inputValue.value = newVal
        }
    }
)
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
<style lang="scss" scoped>
@media (max-width: 1023px) {
    .group-input {
        background: #f6f7fa;
        > div > div {
            display: grid;
            grid-template-columns: 1fr 2.2fr 1fr;
            align-items: center;
            label {
                position: relative;
                order: 1;
                transform: unset;
                padding-left: 5px;
                top: 0;
                &:before {
                    content: unset;
                }
            }
            input {
                order: 2;
                background: none;
                border: none;
                text-align: right;
                padding: 0;
            }
        }
    }
}
</style>
