<template>
    <CommonModalAlert
        :show="showLogoutModal"
        size="sm"
        sticky
        @close="closeSelf"
    >
        <template #header>
            <div></div>
        </template>
        <template #default>
            <div
                class="flex flex-col items-center justify-center space-y-5 rounded-2xl bg-white px-8 py-5 text-sm font-medium"
            >
                <div>
                    <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="22.5" cy="22.5" r="22.5" fill="#F7D936" />
                        <path
                            d="M31.3733 28.7686C32.0732 29.4607 32.0795 30.5892 31.3874 31.2891C29.021 33.6821 25.8647 35 22.5 35C19.1612 35 16.0221 33.6998 13.6612 31.3388C11.3002 28.978 10 25.8389 10 22.5C10 19.1611 11.3002 16.0221 13.6612 13.6612C16.0221 11.3002 19.1611 10 22.5 10C25.8647 10 29.021 11.3179 31.3874 13.7109C32.0795 14.4108 32.0732 15.5393 31.3733 16.2314C30.6734 16.9235 29.5449 16.9172 28.8528 16.2173C27.1612 14.5067 24.905 13.5646 22.5 13.5646C17.573 13.5646 13.5646 17.573 13.5646 22.5C13.5646 27.427 17.573 31.4354 22.5 31.4354C24.9051 31.4354 27.1612 30.4933 28.8528 28.7827C29.5449 28.0829 30.6734 28.0766 31.3733 28.7686ZM37.9947 21.2398L34.4955 17.7406C33.7995 17.0445 32.671 17.0445 31.9749 17.7406C31.2789 18.4366 31.2789 19.5651 31.9749 20.2611L32.4315 20.7177H23.1173C22.133 20.7177 21.335 21.5157 21.335 22.5C21.335 23.4844 22.133 24.2823 23.1173 24.2823H32.4316L31.975 24.7389C31.279 25.435 31.279 26.5634 31.975 27.2595C32.323 27.6075 32.7791 27.7815 33.2352 27.7815C33.6913 27.7815 34.1475 27.6075 34.4955 27.2595L37.9947 23.7603C38.6907 23.0643 38.6907 21.9358 37.9947 21.2398Z"
                            fill="white"
                        />
                    </svg>
                </div>

                <div>
                    {{ $t('modal.logout.do_logout') }}
                </div>
                <div class="flex space-x-4">
                    <button
                        type="button"
                        class="pointer-events-auto rounded-lg bg-bigg-gray bg-gradient-to-tr px-5 py-2.5 text-center text-black"
                        @click="handleLoginOut"
                    >
                        {{ $t('modal.logout.logout') }}
                    </button>
                    <button
                        type="button"
                        class="pointer-events-auto rounded-lg bg-bigg-yellow bg-gradient-to-tr px-5 py-2.5 text-center text-black"
                        @click="closeSelf"
                    >
                        {{ $t('modal.logout.stay') }}
                    </button>
                </div>
            </div>
        </template>
        <template #footer> </template>
    </CommonModalAlert>
</template>
<script setup>
import { useUserStore, useModalStore } from '~/stores'
import { storeToRefs } from 'pinia'

const useUserStoreInstances = useUserStore()
const { logout } = useUserStoreInstances
const useModalStoreInstance = useModalStore()
const { showLogoutModal } = storeToRefs(useModalStoreInstance)

const closeSelf = () => {
    showLogoutModal.value = false
}
const handleLoginOut = async () => {
    try {
        await logout()
    } catch (error) {
        console.error(error)
    } finally {
        showLogoutModal.value = false
    }
}
</script>
