<template>
    <div>
        <div class="flex flex-col items-center">
            <img
                src="/assets/img/forgot-password/change-password-success.png"
                alt="success"
            />
            <h3 class="my-4 text-2xl font-bold">
                {{ $t('modal.forgot_password.successfully') }}
            </h3>
            <button
                class="w-full rounded-lg bg-bigg-yellow px-5 py-3 text-base font-medium md:w-60"
                @click="handleSubmit"
            >
                OK
            </button>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['next'])
const handleSubmit = () => {
    emit('next')
}
</script>
