<template>
    <div class="space-y-4">
        <Veeform
            v-if="step === 1"
            class="space-y-4"
            :validation-schema="verifyPhoneSchema"
            @submit="handleVerifyOtp"
        >
            <CommonPhoneInputFunc
                type="tel"
                name="phone"
                :label="$t('modal.phone.label')"
                :placeholder="$t('modal.signup.phone_placeholder')"
            />
            <CommonTextInputFunc
                type="text"
                name="username"
                :label="$t('modal.login.username')"
                :value="submittedOTP"
                :error="submitOTPError"
                :placeholder="$t('modal.signup.username_placeholder')"
            />

            <div class="flex justify-center">
                <button
                    type="submit"
                    class="mt-[5px] w-full rounded-[4px] bg-bigg-yellow px-5 py-[12px] text-sm font-medium text-black"
                >
                    {{ $t('modal.forgot_password.continue') }}
                </button>
            </div>
        </Veeform>
        <Veeform
            v-if="step === 2"
            class="space-y-4"
            :validation-schema="verifyPhoneOTPSchema"
            @submit="onSubmitChangePassword"
        >
            <CommonTextInputFunc
                name="otp"
                label="OTP"
                :value="submittedOTP"
                :error="submitOTPError"
                :placeholder="$t('common.enter_your_otp')"
            >
                <template #func="{}">
                    <div
                        class="absolute inset-y-0 right-0 z-[301] flex items-center pr-3"
                    >
                        <div
                            v-if="!submittedPhone"
                            class="cursor-pointer text-right text-sm font-medium text-[#B01E46]"
                            @click="handleSendOtp()"
                        >
                            {{ $t('modal.forgot_password.resend') }}
                        </div>
                        <div
                            v-if="submittedPhone && otpCountdown === 0"
                            class="cursor-pointer text-right text-sm font-medium text-[#B01E46]"
                            @click="handleSendOtp()"
                        >
                            {{ $t('modal.forgot_password.resend') }}
                        </div>
                        <div
                            v-if="submittedPhone && otpCountdown > 0"
                            class="text-right text-sm font-medium text-[#AAAAAA]"
                        >
                            {{ `(${otpCountdown})` }}
                        </div>
                    </div>
                </template>
            </CommonTextInputFunc>
            <CommonTextInputFunc
                id="password"
                type="password"
                name="password"
                :label="$t('user.profile.password_tab.new_password')"
                :placeholder="$t('modal.signup.password_placeholder')"
            />
            <CommonTextInputFunc
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                :label="$t('user.profile.password_tab.confirm_new_password')"
                :placeholder="$t('modal.signup.confirm_password')"
            />
            <div class="flex justify-center">
                <button
                    type="submit"
                    class="mt-[5px] w-full rounded-[4px] bg-bigg-yellow px-5 py-[12px] text-sm font-medium text-black"
                >
                    {{ $t('modal.forgot_password.continue') }}
                </button>
            </div>
        </Veeform>
        <div class="mx-auto space-x-1 text-center text-[13px] font-normal">
            <span class="text-[#6E6F74]">
                {{ $t('modal.forgot_password.support_text') }}
            </span>
            <div class="cursor-pointer" @click="loadLiveChat">
                <span class="mr-1 text-[#6E6F74]">
                    {{ $t('common.contact') }}
                </span>

                <span class="text-bigg-red underline">{{
                    $t('modal.forgot_password.support_247')
                }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { Form as Veeform } from 'vee-validate'
import { verifyPhoneSchema } from '~/forms/verifyPhoneSchema.schema'
import { verifyPhoneOTPSchema } from '~/forms/verifyPhoneOTPSchema.schema'
import { useResetPassStore, useAlertStore, useModalStore } from '~/stores'
import { storeToRefs } from 'pinia'
const useModalStoreInstance = useModalStore()
const { showForgotPasswordModal } = storeToRefs(useModalStoreInstance)

const isSubmitting = ref(false)
function loadLiveChat() {
    if (typeof window !== 'undefined') {
        window.LiveChatWidget.call('maximize')
    }
}
const useResetPassStoreInstance = useResetPassStore()
const { verifyByPhone, resetPasswordByPhone } = useResetPassStoreInstance
const {
    submittedOTP,
    submitOTPError,
    submittedOTPByPhone,
    submittedPhone,
    otpCountdown,
} = storeToRefs(useResetPassStoreInstance)

const step = ref(1)
const dataFormStep1 = ref({
    username: '',
    phone: '',
})

const handleSendOtp = async () => {
    try {
        isSubmitting.value = true
        await verifyByPhone(dataFormStep1.value)
    } catch (error) {
        useAlertStore().showMessageError(error)
    } finally {
        isSubmitting.value = false
    }
}

const handleVerifyOtp = async (payload) => {
    try {
        dataFormStep1.value = {
            ...payload,
        }
        isSubmitting.value = true
        await verifyByPhone(payload)
        if (submittedOTPByPhone.value) {
            step.value = 2
        }
    } catch (error) {
        useAlertStore().showMessageError(error)
    } finally {
        isSubmitting.value = false
    }
}

const onSubmitChangePassword = async (payload) => {
    try {
        const queryParams = {
            ...payload,
            username: dataFormStep1.value.username,
        }
        const { data } = await resetPasswordByPhone(queryParams)
        if (data.value.status === 'OK') {
            useAlertStore().showMessage({
                message: data.value.message,
                type: 'SUCCESS',
            })

            showForgotPasswordModal.value = false
        } else {
            useAlertStore().showMessage({
                message: data.value.message,
                type: 'ERROR',
            })
        }
    } catch (error) {
        useAlertStore().showMessageError(error)
    }
}
</script>
