import * as Yup from 'yup'

export const resetPasswordByOtpSchema = Yup.object().shape({
    password: Yup.string()
        .required('error.pass_required')
        .min(6, 'error.min_6_character')
        .max(32, 'error.pass_max_32_character')
        .matches(/^\S*$/, 'error.no_spaces_allowed'),
    confirmPassword: Yup.string()
        .required('error.pass_required')
        .oneOf([Yup.ref('password'), null], 'error.password_must_matche'),
})
