<template>
    <div
        class="relative flex w-full cursor-pointer items-center justify-start gap-2 rounded-md bg-[#03c34d] px-3 py-2 text-white"
        @click="openNewTab(lineContact)"
    >
        <div class="w-6">
            <img
                :src="`${
                    useRuntimeConfig().public.staticUrl
                }/assets/img/footer/line.svg`"
                alt="line chat"
            />
        </div>

        <div class="absolute left-1/2 -translate-x-1/2 text-sm font-medium">
            {{ $t('contact.line') }}
        </div>
    </div>
</template>
<script setup>
const lineContact = useRuntimeConfig().public.LINE_CONTACT
const openNewTab = (url) => {
    const openNewTab = window.open('about:blank', '_blank')
    if (openNewTab) {
        openNewTab.location.href = url
    }
    // navigateTo(url, {
    //     open: {
    //         target: '_blank',
    //     },
    // })
}
</script>
