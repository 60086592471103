<template>
    <CommonModalFunctionV2
        :show="showSupportFunctionModal"
        :title="$t('footer.functions.support')"
        @close="closeSelf"
    >
        <div
            class="flex w-full flex-col items-center justify-center gap-4 bg-white px-6 pb-7"
        >
            <BlocksLiveChat />
            <BlocksLineChat />
            <BlocksContactPhone />
        </div>
    </CommonModalFunctionV2>
</template>
<script setup>
import { useModalStore } from '~/stores'
import { storeToRefs } from 'pinia'
const useModalStoreInstance = useModalStore()
const { showSupportFunctionModal } = storeToRefs(useModalStoreInstance)

const closeSelf = () => {
    showSupportFunctionModal.value = false
}
</script>
