<template>
    <div class="space-y-4">
        <template v-if="step === 1">
            <Veeform
                class="space-y-4"
                :validation-schema="resetByEmailSchema"
                @submit="handleSendOtp"
            >
                <CommonTextInputFunc
                    name="email"
                    label="Email"
                    :value="submittedEmail"
                    :error="submitEmailError"
                    :placeholder="$t('common.enter_your_email')"
                >
                </CommonTextInputFunc>
                <div class="flex justify-center">
                    <button
                        type="submit"
                        class="mt-[5px] w-full rounded-[4px] bg-bigg-yellow px-5 py-[12px] text-sm font-medium text-black"
                    >
                        {{ $t('modal.forgot_password.continue') }}
                    </button>
                </div>
            </Veeform>
            <div class="mx-auto space-x-1 text-center text-[13px] font-normal">
                <span class="text-[#6E6F74]">
                    {{ $t('modal.forgot_password.support_text') }}
                </span>
                <br />

                <div class="cursor-pointer" @click="loadLiveChat">
                    <span class="mr-1 text-[#6E6F74]">
                        {{ $t('common.contact') }}
                    </span>

                    <span class="text-bigg-red underline">{{
                        $t('modal.forgot_password.support_247')
                    }}</span>
                </div>
            </div>
        </template>
        <template v-if="step === 2">
            <Veeform
                v-if="step === 2"
                class="space-y-4"
                :validation-schema="verifyPhoneOTPSchema"
                @submit="onSubmitChangePassword"
            >
                <CommonTextInputFunc
                    name="otp"
                    label="OTP"
                    :value="submittedOTP"
                    :error="submitOTPError"
                    :placeholder="$t('common.enter_your_otp')"
                >
                </CommonTextInputFunc>
                <CommonTextInputFunc
                    id="password"
                    type="password"
                    name="password"
                    :label="$t('user.profile.password_tab.new_password')"
                    :placeholder="$t('modal.signup.password_placeholder')"
                />
                <CommonTextInputFunc
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    :label="
                        $t('user.profile.password_tab.confirm_new_password')
                    "
                    :placeholder="$t('modal.signup.confirm_password')"
                />
                <div class="flex justify-center">
                    <button
                        type="submit"
                        class="mt-[5px] w-full rounded-[4px] bg-bigg-yellow px-5 py-[12px] text-sm font-medium text-black"
                    >
                        {{ $t('modal.forgot_password.continue') }}
                    </button>
                </div>
            </Veeform>
            <div class="mx-auto space-x-1 text-center text-[13px] font-normal">
                <span class="text-[#6E6F74]">
                    {{ $t('modal.forgot_password.support_text') }}
                </span>
                <div class="cursor-pointer" @click="loadLiveChat">
                    <span class="mr-1 text-[#6E6F74]">
                        {{ $t('common.contact') }}
                    </span>

                    <span class="text-bigg-red underline">{{
                        $t('modal.forgot_password.support_247')
                    }}</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { Form as Veeform } from 'vee-validate'
import { useModalStore, useResetPassStore, useAlertStore } from '~/stores'
import { resetByEmailSchema } from '~/forms/resetByEmailSchema.schema'
import { verifyPhoneOTPSchema } from '~/forms/verifyPhoneOTPSchema.schema'
import { storeToRefs } from 'pinia'
const useModalStoreInstance = useModalStore()
const { showForgotPasswordModal } = storeToRefs(useModalStoreInstance)

const isSubmitting = ref(false)
function loadLiveChat() {
    if (typeof window !== 'undefined') {
        window.LiveChatWidget.call('maximize')
    }
}
const useResetPassStoreInstance = useResetPassStore()
const { sendOtp, resetPasswordByOtp } = useResetPassStoreInstance
const { submittedEmail, submittedOTP, submitOTPError, submitEmailError } =
    storeToRefs(useResetPassStoreInstance)
const dataFormStep1 = ref({
    username: '',
    email: '',
})
const step = ref(1)

const handleSendOtp = async (payload) => {
    try {
        isSubmitting.value = true
        const toSend = {
            email: payload.email,
        }
        await sendOtp(toSend)
        if (submittedEmail && !submitEmailError.value) {
            step.value = 2
            dataFormStep1.value = {
                ...payload,
            }
        }
    } catch (error) {
        useAlertStore().showMessageError(error)
    } finally {
        isSubmitting.value = false
    }
}
const onSubmitChangePassword = async (payload) => {
    try {
        const queryParams = {
            ...payload,
            email: dataFormStep1.value.email,
        }
        const { data } = await resetPasswordByOtp(queryParams)
        if (data.value.status === 'OK') {
            useAlertStore().showMessage({
                message: data.value.message,
                type: 'SUCCESS',
            })

            showForgotPasswordModal.value = false
        } else {
            useAlertStore().showMessage({
                message: data.value.message,
                type: 'ERROR',
            })
        }
    } catch (error) {
        useAlertStore().showMessageError(error)
    }
}
</script>
