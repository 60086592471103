import * as Yup from 'yup'

export const loginSchema = Yup.object().shape({
    username: Yup.string()
        .required('error.required')
        .min(6, 'error.min_6_character')
        .max(30, 'error.max_30_character')
        .matches(/^[\u0E00-\u0E7Fa-zA-Z0-9_]+$/, 'error.alphanumeric_only'),
    password: Yup.string()
        .required('error.pass_required')
        .min(6, 'error.pass_min_6_character')
        .max(32, 'error.pass_max_32_character'),
})
